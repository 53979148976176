const DocUploadIcon = ({ size = 21, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.54346 5.95824L10.8901 3.61157L13.2368 5.95824" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.8901 12.9983V3.67578" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.6665 11C3.6665 15.0517 6.4165 18.3333 10.9998 18.3333C15.5832 18.3333 18.3332 15.0517 18.3332 11" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  );
};

export default DocUploadIcon;
