import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import StyledText from "../Components/StyledComponents/StyledText";
import { svenskaLs } from "../Services/Utils/localStorage";
import * as Yup from "yup";
import {
  useCreateUserMutation,
  useGetSignupOrderRefMutation,
} from "../Services/loginApi/loginApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../Services/Auth/authSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import theme from "../Services/Utils/themes";
import { DefaulImage, useWindowSize } from "../Services/Utils/helper";
import { Form, Formik } from "formik";
import FormikInputField from "../Components/Formik/FormikInputField";
import StyledButton from "../Components/StyledComponents/StyledButton";
import { useNavigate } from "react-router-dom";
import svenskaLogo from "../Assets/Images/svenskaLogo.png";
import { Row } from "react-bootstrap";


const SignupRedirectPage = () => {
  const { t, i18n } = useTranslation();
  let orderRef = svenskaLs.getObject("orderRef");
  const lang = svenskaLs.getItem("lng") || "swe";
  const [userInfo, setUserInfo] = useState("");
  const [newUser, setNewUser] = useState("");
  const isSmallScreen = useWindowSize();
  const dispatch = useDispatch();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const navigate = useNavigate();
  const [orderRefCollect] = useGetSignupOrderRefMutation();



  useEffect(() => {
    // Function to clear localStorage if user closes the tab or browser
    const handleUnload = () => {
      const userData = svenskaLs.getObject('userDetails');
      setUserInfo(userData);
      if (!userData) {
        console.log("No user data found, clearing localStorage.");
        svenskaLs.clear();
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);


  useEffect(() => {
    if (svenskaLs.getItem("pageReload") === null) {
      if (!userInfo && !newUser) {
        svenskaLs.setItem("pageReload", 1);
        orderRefCollect(orderRef)
          .then((resp) => {
            if (resp?.data?.isExist) {
              toast.error(t("common:userAlreadyExists"));
              svenskaLs.removeItem('pageReload');
              svenskaLs.removeItem('userDetails');
              setTimeout(() => {
                window.close();
              }, 3000);
            } else if (resp?.data?.userData) {
              setNewUser(resp.data.userData);
              svenskaLs.removeItem('pageReload');
              svenskaLs.setObject('userDetails', resp.data.userData);
            } else {
              svenskaLs.removeItem('pageReload');
              svenskaLs.removeItem('userDetails');
              toast.error(resp?.error?.data?.message || 'An unexpected error occurred');
            }
          })
          .catch((error) => {
            // Log the error for debugging purposes
            console.error('Error during orderRefCollect:', error);
            svenskaLs.removeItem('pageReload');
            svenskaLs.removeItem('userDetails');
            // Display a generic error message to the user
            toast.error('An error occurred while processing your request');
          });
      }
    } else {
      navigate("/signup");
      svenskaLs.removeItem("pageReload");
      svenskaLs.removeItem("orderRef");
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values) => {
    try {
      const fName = userInfo
        ? userInfo?.name?.split(" ")
        : newUser?.name?.split(" ");

      const userData = {
        firstName: fName[0] ?? "",
        lastName: fName?.slice(1)?.join(" ") ?? "",
        email: values?.email,
        personalNumber: userInfo
          ? userInfo?.personalNumber
          : newUser?.personalNumber,
        trans: lang,
        phoneNumber: values?.phone,
        ...values,
      };

      const { data } = await createUser(userData).unwrap();
      if (data?.token) {
        svenskaLs.removeItem("userDetails");
        dispatch(
          setCredentials({
            token: { accessToken: data?.token },
            account: data?.userInfo,
            userType: data?.userInfo?.userType,
          })
        );
        svenskaLs.removeItem("orderRef");
        svenskaLs.setItem("loadingComplete", "true");
        navigate("/dashboard");
      } else {
        toast.error(t("common:alreadyExistErrorMessage"), {
          autoClose: 5000,
        });
      }

    } catch (error) {
      console.error("Error creating user:", error);
      toast.error(error?.data?.message);
      setTimeout(() => {
        window.close();
      }, 5000);

    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        t("common:invalidEmail")
      )
      .required(t("common:emailRequired")),

    phone: Yup.string()
      .required(t("common:phoneRequired"))
      .matches(/^\d+$/, t("common:phoneRequiredDigit")),

    country: Yup.string().required(t("common:countryRequired")),
    // county: Yup.string().required(t("common:cityRequired")),
    town: Yup.string().required(t("common:townRequired")),
    streetAddress: Yup.string().required(t("common:streetAddressRequired")),
    postalCode: Yup.string().required(t("common:postalCodeRequired")),
  });

  return (
    <StyledContainer
      className={` d-flex flex-column align-items-center p-4 ${userInfo || newUser ? "border rounded bg-white" : ""
        }`}
    >
      {userInfo || newUser ? (
        <div className="d-flex flex-column align-items-center p-md-5 ">
          <LogoContainer className="w-100 pb-5 d-flex align-items-center justify-content-center">
            <img
              src={svenskaLogo}
              width={isSmallScreen ? 191 : 292}
              alt="svesnkaLogo"
            />
          </LogoContainer>
          <StyledText
            as="h1"
            $fontWeight={600}
            $fontSize={isSmallScreen ? "20px" : "28px"}
            color={theme.secondary}
            className="text-center pb-3"
          >
            {t("common:verifyYourDetials")}
          </StyledText>
          <div className="position-relative border w-100 mt-5">
            <StyledImg
              className="rounded-circle"
              src={DefaulImage.image}
              alt=""
            />
            <div className="text-center pt-5 pb-3">
              <StyledText $fontSize="15px" $fontWeight={600}>
                {t("common:textFullName")} :
              </StyledText>{" "}
              <StyledText $fontSize="15px" color={theme.gray}>
                {userInfo ? userInfo?.name : newUser?.name}
              </StyledText>
              <br />
              <StyledText $fontSize="15px" $fontWeight={600}>
                {t("common:textPersonalNoFull")} :
              </StyledText>{" "}
              <StyledText $fontSize="15px" color={theme.gray}>
                {userInfo ? userInfo?.personalNumber : newUser?.personalNumber}
              </StyledText>
            </div>
            {/* <FormikInputField /> */}
          </div>
          <Formik
            initialValues={{
              email: "",
              phone: "",
              country: "",
              county: "",
              town: "",
              streetAddress: "",
              postalCode: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="w-100 pt-4">
              <Row>
                <div className="col-12 col-md-6">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.oxfordBlue}
                    >
                      {t("common:textEmailAddress")}
                    </StyledText>
                    <StyledText
                      as="label"
                      htmlFor="country"
                      color={theme.danger}
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    type="text"
                    label="Email"
                    name="email"
                    key={i18n.language}
                    placeholder={t("common:enterEmail")}
                  />
                </div>

                <div className="col-12 col-md-6 mt-3 mt-md-0">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.oxfordBlue}
                    >
                      {t("common:phone")}
                    </StyledText>
                    <StyledText
                      as="label"
                      htmlFor="country"
                      color={theme.danger}
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    label="phone"
                    name="phone"
                    key={i18n.language}
                    placeholder={t("common:enterPhoneNo")}
                  />
                </div>

                <div className="col-12 col-md-6 mt-3">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      as="label"
                      $fontWeight={500}
                      $fontSize="14px"
                      htmlFor="country"
                      color={theme.headingTextColor}
                      className="ml-1"
                    >
                      {t("common:textCountry")}
                    </StyledText>
                    <StyledText
                      as="label"
                      htmlFor="country"
                      color={theme.danger}
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    key={i18n.language}
                    name="country"
                    id="country"
                    placeholder={t("common:enterCountry")}
                  />
                </div>

                <div className="col-12 col-md-6 mt-3">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      as="label"
                      htmlFor="county"
                      $fontWeight={500}
                      $fontSize="14px"
                      color={theme.headingTextColor}
                      className="ml-1"
                    >
                      {t("common:county")}
                    </StyledText>
                    {/* <StyledText
                        as='label'
                        htmlFor='name'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText> */}
                  </div>
                  <FormikInputField
                    key={i18n.language}
                    name="county"
                    id="county"
                    placeholder={t("common:enterCounty")}
                  />
                </div>

                <div className="col-12 col-md-6 mt-3">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      as="label"
                      htmlFor="town"
                      $fontWeight={500}
                      $fontSize="14px"
                      color={theme.headingTextColor}
                      className="ml-1"
                    >
                      {t("common:textTown")}
                    </StyledText>
                    <StyledText
                      as="label"
                      htmlFor="name"
                      color={theme.danger}
                      className="mt-0"
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    key={i18n.language}
                    name="town"
                    id="town"
                    placeholder={t("common:enterTown")}
                  />
                </div>

                <div className="col-12 col-md-6 mt-3">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      as="label"
                      htmlFor="streetAddress"
                      $fontWeight={500}
                      $fontSize="14px"
                      color={theme.headingTextColor}
                      className="ml-1"
                    >
                      {t("common:streetAddress")}
                    </StyledText>
                    <StyledText as="label" htmlFor="name" color={theme.danger}>
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    key={i18n.language}
                    name="streetAddress"
                    id="streetAddress"
                    placeholder={t("common:enterStreetAdress")}
                  />
                </div>

                <div className="col-12 col-md-6 mt-3">
                  <div className="d-flex align-items-baseline mb-1">
                    <StyledText
                      as="label"
                      htmlFor="postalCode"
                      $fontWeight={500}
                      $fontSize="14px"
                      color={theme.headingTextColor}
                      className="ml-1"
                    >
                      {t("common:textPostalCode")}
                    </StyledText>
                    <StyledText
                      as="label"
                      htmlFor="name"
                      color={theme.danger}
                      className="mt-2"
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    key={i18n.language}
                    name="postalCode"
                    id="postalCode"
                    placeholder={t("common:enterPostalCode")}
                  />
                </div>

                <div className=" d-flex align-items-center justify-content-center pt-5 text-center flex-wrap gap-2">
                  <StyledBtn
                    type="button"
                    onClick={() => {
                      svenskaLs.removeItem("orderRef");
                      svenskaLs.removeItem("userDetails");
                      svenskaLs.removeItem('pageReload');
                      window.close();
                    }}
                    className="btn btn-light px-4"
                  >
                    {t("common:textBtnCancle")}
                  </StyledBtn>
                  <StyledButton
                    type="submit"
                    className="btn btn-primary text-white px-4"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    {t("client:AccessYourWorkplace")}
                  </StyledButton>
                </div>
              </Row>
            </Form>
          </Formik>
        </div>
      ) : (
        <>
          <div className="spinner-border text-primary loader" role="status" />
          <StyledText>Redirecting...</StyledText>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;

const StyledImg = styled.img`
  width: 75px;
  height: 75px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledBtn = styled.button`
  background-color: ${({ theme }) => theme.silverColor};
`;

const LogoContainer = styled.div`
  text-align: center;
  @media screen and (max-width: 575px) {
    text-align: start;
  }
`;
export default SignupRedirectPage;
