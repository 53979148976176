import React, { useState } from 'react'
import PaginationPerPage from '../../../Components/pagination/PaginationPerPage'
import { useTranslation } from 'react-i18next';
import StyledImage from '../../../Components/StyledComponents/StyledImage';
import { DefaulImage, STATUSES, capitalizeFirstLetter, filterHtml, fonts, getIconByFileExtension, getRelativeTime, handleErrorImg, handleViewDocs, useWindowSize } from '../../../Services/Utils/helper';
import StyledText from '../../../Components/StyledComponents/StyledText';
import theme from '../../../Services/Utils/themes';
import FileAttachmentLable from '../../../Components/FileAttachmentLable';
import EditIcon from '../../../Components/Svgicons/EditIcon';
import DeleteIcon from '../../../Components/Svgicons/DeleteIcon';
import { BorderLine, StyledBadge, StyledContainer, UnreadCountCircle } from '../../../Components/StyledComponents/CommonStyled';
import ViewIcon from '../../../Components/Svgicons/ViewIcon';
import CommonTable from '../CommonTable';
import StyledTableSkeleton from '../../../Components/StyledComponents/StyledTableSkeleton';
import { svenskaLs } from '../../../Services/Utils/localStorage';
import styled from 'styled-components';
import MessageIcon from '../../../Components/Svgicons/MessageIcon';
import AdminDocumentPostingIcon from '../../../Components/Svgicons/AdminDocumentPostingIcon';
import StyledToolTip from '../../../Components/StyledComponents/StyledToolTip';
import moment from 'moment';

const DocumentsTable = ({ clientDetails, handlePerPageChange, handlePageChange, setaddClientModalOpen, setIsEdit, setDeleteData, setDocId,setS3, loadClients, refetchData, handleConditionModal, loader }) => {
    const { t } = useTranslation();
    const [loadingId, setLoadingId] = useState(null);
    const column = [
        {
            id: 1,
            path: "title",
            label: (
                <StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" truncateWidth="186px">
                    {t("admin:documentName")}
                </StyledText>
            ),
            isChecked: true,
            alignBody: `text-left align-middle fw-bold px-3 px-md-5 py-3`,
            alignHeader: "text-left align-middle cursor-pointer px-3 px-md-5 py-3",
            content: (docs, index) => (
                <>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                        {getIconByFileExtension(docs?.fileName)}
                        </div>
                        <StyledText
                            $fontWeight={600}
                            $fontSize="13px"
                            color={theme.secondary}
                            $fontFamily={fonts.raleway}
                            className="text-nowrap "
                        >
                            {docs?.fileName}
                        </StyledText>
                    </div>
                </>
            ),
        },
        {
            id: 2,
            path: "userType",
            label: (
                <StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" truncateWidth="140px">{t("common:uploadedDate")} </StyledText>
            ),
            alignBody: " align-middle text-center",
            alignHeader: "text-center align-middle",
            content: (docs, index) => (
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <StyledText
                        $fontWeight={500}
                        $fontSize="13px"
                        color={theme.stormDust}
                        $fontFamily={fonts.quicksand}
                    >
                        {moment(docs?.createdAt).format("dddd MM, YYYY")}
                    </StyledText>
                </div>
            ),
        },
        {
            id: 3,
            path: "userStatus",
            label: (
                <StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" truncateWidth="155px">{t("common:lastUpdated")} </StyledText>
            ),
            isChecked: true,
            alignBody: "align-middle",
            alignHeader: "align-middle text-center",
            content: (docs, index) => (
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <StyledText
                        $fontWeight={500}
                        $fontSize="13px"
                        $fontFamily={fonts.quicksand}
                        color={theme.stormDust}
                    >
                        {getRelativeTime(docs?.createdAt)}
                    </StyledText>
                </div>
            ),
        },
        {
            id: 4,
            label: (<StyledText as="span" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px">{t("admin:titleTblActionCol")}</StyledText>),
            alignBody: "align-middle px-5",
            alignHeader: "align-middle text-center px-5",
            content: (docs) => (
                <div className="d-flex align-items-center justify-content-center gap-2">

                    <div
                        className={`cursor-pointer text-muted bg-snowWhite p-2 rounded`}
                        onClick={() => {
                            setDeleteData(true);
                            setDocId(docs?.id);
                            setS3(docs?.fileDescriptor);

                        }}
                    >
                        <DeleteIcon size="18" />
                    </div>
                    <div
                        className={`cursor-pointer text-muted  p-2 rounded bg-snowWhite`}
                        onClick={() => handleViewDocs(docs?.signedUrl)}
                    >
                        <ViewIcon size="18" />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <StyledContainer className='mt-4 p-0 border rounded' height={clientDetails?.perPage > 5 && '273px'} zeroState={!loadClients && (clientDetails?.data?.length === 0 || clientDetails?.data?.length === undefined)}>
                {!loadClients && (clientDetails?.data?.length === 0 || clientDetails?.data?.length === undefined) ?

                    <div className="d-flex flex-column align-items-center justify-content-center h-100">

                        <StyledText
                            color={theme.gray}
                            $fontWeight={600}
                            $fontSize="20px"
                            className="text-center mt-3"
                        >
                            {t("common:noAIDocument")}
                        </StyledText>
                        <StyledText
                            color={theme.gray}
                            $fontWeight={500}
                            $fontSize="14px"
                            className="text-center"
                        >
                            {t("common:aiDescription")}
                        </StyledText>
                    </div> : loadClients ?
                        <StyledTableSkeleton cols={column} isFilter={true} /> :
                        <CommonTable data={clientDetails?.data} column={column} />
                }

            </StyledContainer>
            {loadClients ? null : !loadClients && (clientDetails?.data?.length === 0 || clientDetails?.data?.length === undefined) ? null : <PaginationPerPage
                perPage={clientDetails?.perPage || 0}
                pageNo={clientDetails?.page || 0}
                onPerPageChange={handlePerPageChange}
                totalCount={clientDetails?.count || 0}
                onPageChange={handlePageChange}
            />}
        </>

    )
}




export default DocumentsTable