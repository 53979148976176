import React from 'react'

const AddUser = ({size = 54}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 27C33.2132 27 38.25 21.9632 38.25 15.75C38.25 9.5368 33.2132 4.5 27 4.5C20.7868 4.5 15.75 9.5368 15.75 15.75C15.75 21.9632 20.7868 27 27 27Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.67261 49.5C7.67261 40.7925 16.3351 33.75 27.0001 33.75C29.1601 33.75 31.2526 34.0425 33.2101 34.5825" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M49.5 40.5C49.5 41.22 49.41 41.9175 49.23 42.5925C49.0275 43.4925 48.6675 44.37 48.195 45.135C46.6425 47.745 43.785 49.5 40.5 49.5C38.1825 49.5 36.09 48.6224 34.515 47.1824C33.84 46.5974 33.255 45.9 32.805 45.135C31.9725 43.785 31.5 42.1875 31.5 40.5C31.5 38.07 32.4675 35.8425 34.0425 34.2225C35.685 32.535 37.98 31.5 40.5 31.5C43.155 31.5 45.5625 32.6475 47.1825 34.4925C48.6225 36.09 49.5 38.205 49.5 40.5Z" stroke="white" strokeWidth="2.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M43.8524 40.455H37.1475" stroke="white" strokeWidth="2.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.5 37.17V43.8975" stroke="white" strokeWidth="2.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default AddUser