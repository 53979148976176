import styled, { css } from 'styled-components';
import Proptypes from 'prop-types';

const StyledText = styled(({ children, as: Component, ...props }) => (
  <Component {...props}>{children}</Component>
))`
  ${({ $fontSize }) => `${$fontSize ? 'font-size:' + $fontSize : ''};`}
  

  color: ${({ color, theme }) => (color ? color : theme.secondary)};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 400)};  
  font-family: ${({ $fontFamily }) => ($fontFamily && $fontFamily)};  

  ${({ isHover, theme }) =>
    isHover && `cursor: pointer; &:hover {color: ${theme.primary}}`}
    text-overflow: ${({ truncate }) => (truncate ? 'ellipsis' : '')} !important;
  white-space: ${({ whiteSpace }) => (whiteSpace && whiteSpace)} !important;
word-break: ${({ wordbreak }) => (wordbreak && 'break-all')} !important;
    word-wrap: break-word;
  width: ${({ truncateWidth }) => truncateWidth} !important;
  overflow: ${({ truncate }) => (truncate ? 'hidden' : '')} !important;
  text-transform: ${({ textCapitalize }) => (textCapitalize ? 'capitalize' : '')};
  line-height: ${({ lh }) => (lh && lh)};
  p {
    margin: 0;  // Remove margin from <p> tags
  }
  ${({ truncatedlines }) =>
    truncatedlines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${truncatedlines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      // word-break: break-all;
      white-space: break-spaces;
    `}
`;

StyledText.defaultProps = {
  as: 'span',
};
StyledText.propTypes = {
  as: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.node,
    Proptypes.element,
    Proptypes.object,
  ]),
  $fontSize: Proptypes.string,
};
export default StyledText;
