const InfoIcon = ({
  size = 21,
  rotate = "down",
  transition,
  color = "white",
  ...props
}) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 8.25C6.5625 8.25 8.25 6.5625 8.25 4.5C8.25 2.4375 6.5625 0.75 4.5 0.75C2.4375 0.75 0.75 2.4375 0.75 4.5C0.75 6.5625 2.4375 8.25 4.5 8.25Z"
        stroke="#35292D"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 3V4.875"
        stroke="#35292D"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.49792 6H4.50129"
        stroke="#35292D"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoIcon;
