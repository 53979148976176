// socket.js
import { io } from 'socket.io-client';
import { svenskaLs } from '../Services/Utils/localStorage';

let socket;

const initializeSocket = () => {
    const lsToken = svenskaLs.getObject("token");

    if (!lsToken) {
        console.warn("Token not found in localStorage. Waiting to initialize socket...");
        return;
    }

    socket = io(process.env.REACT_APP_API_URL, {
        auth: {
            token: lsToken, // Send the token as part of the auth object
        },
    });

    console.log("Socket initialized with token:", lsToken);
};

export const getSocket = () => {
    if (!socket) {
        initializeSocket();
    }
    return socket;
};
