import styled from "styled-components";
import theme from "../../../Services/Utils/themes";

export const Container = styled.div`
background-color: ${theme.backgroundBody};
height: 100vh;
padding: 10px;

`

