import styled from "styled-components"
import dottedBg from "../../../Components/Svgicons/Dottedbg.png";
import theme from "../../../Services/Utils/themes";

export const ChatContainer = styled.div`
background-image: url(${dottedBg});
height: calc(100vh - 95px);
border-radius: 0 0 8px 8px;
display: flex;
justify-content: center;
position:relative;
`;

export const ChatBotContainer = styled.div `
width: min(900px, 90%);
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: ${({ispadding}) => ispadding ? '10px' : '60px'};
padding-bottom: 10px;
@media (max-width: 768px) {
padding-top: ${({ispadding}) => ispadding ? '10px' : '40px'};
  
}
`;

export const Icon = styled.div`
background-color: ${theme.secondary};
height: 72px;
width: 72px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 6px;

`

export const Card = styled.div`
background-color: ${theme.white};
max-width: 380px;
border-radius: 8px;
box-shadow: 0px 4px 40px 0px #35292D0D;
padding: 15px;
padding-bottom: 0;

`


export const InputContainer = styled.div`
width: 100%;
display: flex;
align-items:end;
`

export const StyledChatBox = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
overflow-y: auto;
 scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
 &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`

export const CustomInput = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  min-height: 52px;
  padding: 1px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  
`;

export const SubmitButton = styled.div`
height: 48px;
width: 48px;
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
background: linear-gradient(180deg, #A85A21 0%, #C0833B 100%);
cursor: pointer;

`