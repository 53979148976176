import React from "react";
import { Modal } from "react-bootstrap";
import StarsIcon from "../Svgicons/StarsIcon";
import StyledText from "../StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import StyledButton from "../StyledComponents/StyledButton";
import UserIcon from "../Svgicons/UserIcon";
import { useNavigate } from "react-router-dom";
import { svenskaLs } from "../../Services/Utils/localStorage";
import { USERTYPE } from "../../Services/Utils/helper";
import { useTranslation } from "react-i18next";
import AiStartIcon from "../Svgicons/AiStartIcon";

const ChatLimitExceedModal = ({ show, onHide, handleNewChat }) => {
  const nav = useNavigate();
  const { t } = useTranslation();
  let userType = svenskaLs.getObject("userType");
  const lsToken = svenskaLs.getObject("token");

  const userIsAdmin = userType === USERTYPE?.ADMIN;
  const userIsClient = userType === USERTYPE?.CLIENT;
  const userIsNominee = userType === USERTYPE?.NOMINEE;

  let isPrivateChat =
    (lsToken && userIsAdmin) ||
    (lsToken && userIsClient) ||
    (lsToken && userIsNominee);

  console.log(isPrivateChat, "isPrivateChat");

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="bg-body rounded py-5 px-5">
        <div className="d-flex flex-column align-items-center text-center gap-4">
          <div>
            <StarsIcon width={61} height={61} />
          </div>

          <div className="d-flex flex-column gap-2">
            <StyledText
              $fontSize="20px"
              $fontWeight={600}
              color={theme.secondary}
            >
              {isPrivateChat ? t("common:chatLimit") : t("common:tankFull")}
            </StyledText>

            <StyledText $fontSize="16px" $fontWeight={400} color={theme.gray}>
              {isPrivateChat
                ? t("common:chatLimitDescription")
                : t("common:tankFullDescription")}
            </StyledText>
          </div>

          {isPrivateChat ? (
            <StyledButton
              icon={<AiStartIcon />}
              variant="primary"
              className="px-4"
              onClick={handleNewChat}
            >
              {t("common:startNewChat")}
            </StyledButton>
          ) : (
            <StyledButton
              icon={<UserIcon />}
              variant="primary"
              className="px-4"
              onClick={() => nav("/login")}
            >
              {t("common:loginToContinue")}
            </StyledButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChatLimitExceedModal;
