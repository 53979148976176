import styled from "styled-components";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const StyledButton = ({ loading, children, icon, ...props }) => (
  <ButtonStyled {...props} _children={children}>
    <div
      className={`spinner spinner-border spinner-border-sm  ${
        !loading ? "d-none" : "d-inline-block"
      }`}
      style={{
        transition: "all 0.3s linear",
      }}
    />
    {icon && !loading && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon}
      </div>
    )}
    {children ? (
      <div
        style={{
          transition: "margin-left 0.3s ease-in flex-nowrap",
        }}
      >
        {children}
      </div>
    ) : null}
  </ButtonStyled>
);

const ButtonStyled = styled(Button)`
  ${({ variant, theme, height, borderColor, mh }) => {
    const basicProperties = `
    display: flex;
    white-space: nowrap;
    max-height: ${mh ? "auto" : "38px"};
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    border-radius:3px;
    border: ${borderColor ? `1px solid ${borderColor}` : "none"} !important;
    font-weight:500px !important;
    `;
    switch (variant) {
      case "white":
        return `
        ${basicProperties}
        color: ${theme.secondary};
        &:hover{
          background: ${theme.silverColor};
          color: ${theme.black};
          box-shadow: none;
          
        }
     

        `;

      case "primary":
        return `
        ${basicProperties}
        color: ${theme.white};
        background: ${theme.primary};
        &:hover, &:active, &:focus {
                background: ${theme.primary} !important;
                box-shadow: none;
                color: ${theme.white} !important;
              }
         
        `;

      case "silver":
        return `
        ${basicProperties}
        color: ${theme.primary};
        background: ${theme.bgBody};
        border:1px solid ${theme.lightGray};
        &:hover{
          color: ${theme.primary};
        background: ${theme.silverColor};
          border: 1px solid ${theme.dimGray};
          box-shadow: none;
          svg {
           fill: ${theme.white}!important;
          }
        }
        `;

      case "snowWhite":
        return `
        ${basicProperties}
        color: ${theme.stormDust};
        background: ${theme.snowWhite};
        border:none;
        &:hover{
        color: ${theme.stormDust};
        background: ${theme.lavenderGray};
          border:none;
          box-shadow: none;
        }
        `;
      case "added":
        return `
          ${basicProperties}
          color: ${theme.finch};
          background: ${theme.berylGreen};
          padding-right: 33px;
          padding-left: 32px;
          &:hover{
            color: ${theme.finch};
          background: ${theme.berylGreen};

            box-shadow: none;
            svg {
             fill: ${theme.berylGreen}!important;
            }
          }
          `;
      case "text":
        return `
        ${basicProperties}
        color: ${theme.inputBody};
        background: transparent;
        &:hover, &:focus, &:active {
         background: transparent;
          color: ${theme.primary};
          box-shadow: none;
          border-color: transparent!important;
        }
        `;
      case "icon":
        return `
          ${basicProperties}
          color: ${theme.inputColor};
          background: transparent;
          border: 1px solid ${theme.inputBorder};
          &:hover, &:active, &:focus {
          border: 1px solid ${theme.inputBorder};
          background: transparent;
            color: ${theme.primary};
            box-shadow: none;
          }
          `;
      case "gray":
        return `
              ${basicProperties}
              color: ${theme.gray};
              background: ${theme.silverColor};
              border-color: ${theme.lightGray} !important;
              &:hover{
              color: ${theme.white} !important;
              background: ${theme.gray};
              }
              `;
      case "iconBackground":
        return `
              ${basicProperties}
              color: ${theme.inputColor};
              background: ${theme.white};
              border: 1px solid ${theme.inputBorder};
              padding:13px;
              max-height: ${height ? height + "px" : ""};
              &:hover, &:active, &:focus {
                background: ${theme.primary};
                color: ${theme.white} !important;
                box-shadow: none;
              }
              `;
      case "border":
        return `
              ${basicProperties}
              background: ${theme.white};
              border: 1px solid ${theme.primary};
              padding:13px;
              &:hover, &:active, &:focus {
                border: 2px solid ${theme.primary};
                box-shadow: none;
                color: ${theme.white} !important;
              }
              `;
      default:
        return `
        ${basicProperties}
        `;
    }
  }}
`;

ButtonStyled.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.element,
};
export default StyledButton;
