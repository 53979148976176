import React from "react";
import { Modal, Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { styled } from "styled-components";
import { Formik, Form } from "formik";
import FormikInputField from "../../Components/Formik/FormikInputField";
import AddIcon from "../../Components/Svgicons/AddIcon";
import * as Yup from "yup";
import CrossIcon from "../../Components/Svgicons/CrossIcon";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import UpdateIcon from "../../Components/Svgicons/UpdateIcon";
import {
  useCreateNomineeMutation,
  useUpdateNomineeMutation,
} from "../../Services/DashboardApi/dashboardApiSlice";

const AddNomineeModal = ({
  show,
  onHide,
  refetch,
  file,
  isEdit,
  setNomineeDetails,
}) => {
  const { t, i18n } = useTranslation();
  const [createNominee, { isLoading: newLoading }] = useCreateNomineeMutation();
  const [updateNominee, { isLoading: updateLoading }] =
    useUpdateNomineeMutation();

  const handleSubmit = (values) => {
    values.fileId = isEdit ? isEdit?.fileId : file?.id;
    const call = isEdit ? updateNominee : createNominee;
    call(values).then((Resp) => {
      onHide();
      if (Resp?.error?.status === 400) {
        toast.error(
          Resp?.error?.data?.message || t("admin:nomineeAlreadyExist")
        );
      } else {
        if (!Resp?.error?.status) {
          toast.success(
            isEdit
              ? t("client:nomineeUpdatedSuccessfully", {
                  firstname: values?.firstName,
                  lastname: values?.lastName,
                })
              : t("client:nomineeAddedSuccessfully", {
                  firstname: values?.firstName,
                  lastname: values?.lastName,
                })
          );
          refetch();
          setNomineeDetails("");
        }
      }
    });
  };

  const validationSchema = Yup.object().shape({
    // email: Yup.string().email(t('common:invalidEmail')).required(t('common:emailRequired')),
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        t("common:invalidEmail")
      )
      .required(t("common:emailRequired")),
    firstName: Yup.string().required(t("common:firstNameRequired")),
    lastName: Yup.string().required(t("common:lastNameRequired")),
    phoneNumber: Yup.string()
      .required(t("common:phoneRequired"))
      .matches(/^\d+$/, t("common:phoneRequiredDigit")),
    personalNumber: Yup.string()
      .matches(/^\d{12}$/, t("common:personalNumberDigitRequired"))
      .required(t("common:personalNumberRequired")),
    country: Yup.string().required(t("common:countryRequired")),
    // county: Yup.string().required(t("common:cityRequired")),
    town: Yup.string().required(t("common:townRequired")),
    streetAddress: Yup.string().required(t("common:streetAddressRequired")),
    postalCode: Yup.string().required(t("common:postalCodeRequired")),
  });

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="d-flex flex-column bg-body rounded p-3 p-sm-5">
        <div className="d-flex align-item-center justify-content-between">
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <StyledText
              as="h1"
              $fontSize="20px"
              $fontWeight={600}
              color={theme.secondary}
            >
              {isEdit
                ? t("client:editTitleNewNominee")
                : t("client:titleNewNominee")}
            </StyledText>
            <StyledText
              as="p"
              $fontSize="14px"
              $fontWeight={500}
              color={theme.gray}
            >
              {t("client:descNewNomineeModal")}
            </StyledText>
          </div>
          <div onClick={onHide} className="cursor-pointer d-block d-md-none">
            <CrossIcon />
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            country: "",
            county: "",
            town: "",
            streetAddress: "",
            personalNumber: "",
            postalCode: isEdit?.user?.postCode ?? "",
            notes: isEdit?.notes ?? "",
            ...isEdit?.user,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <StyledContainer className=" border-top my-3">
                <Row>
                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="firstName"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textFirstName")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="firstName"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="firstName"
                      id="firstName"
                      placeholder={t("common:enterFirstName")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="lastName"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textLastName")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="lastName"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="lastName"
                      id="lastName"
                      placeholder={t("common:enterLastName")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="email"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textEmail")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="email"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="email"
                      type="email"
                      id="email"
                      placeholder={t("common:enterEmail")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="phoneNumber"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textPhoneNumber")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="phoneNumber"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder={t("common:enterPhoneNo")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        $fontWeight={600}
                        $fontSize="14px"
                        htmlFor="country"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textCountry")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="country"
                        color={theme.danger}
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="country"
                      id="country"
                      placeholder={t("common:enterCountry")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="county"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:county")}
                      </StyledText>
                      {/* <StyledText
                        as='label'
                        htmlFor='name'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText> */}
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="county"
                      id="county"
                      placeholder={t("common:enterCounty")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="town"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textTown")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-0"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="town"
                      id="town"
                      placeholder={t("common:enterTown")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="streetAddress"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:streetAddress")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="streetAddress"
                      id="streetAddress"
                      placeholder={t("common:enterStreetAdress")}
                    />
                  </div>

                  <div
                    className={`mt-4 col-md-6 ${
                      isEdit ? "feature-pending" : ""
                    }`}
                  >
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        $fontWeight={600}
                        $fontSize="14px"
                        htmlFor="personalNumber"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textPersonalNoFull")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="personalNumber"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="personalNumber"
                      id="personalNumber"
                      placeholder={t("common:enterPersonalNo")}
                    />
                  </div>

                  <div className="mt-4 col-md-6">
                    <div className="d-flex align-items-baseline mb-1">
                      <StyledText
                        as="label"
                        htmlFor="postalCode"
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        {t("common:textPostalCode")}
                      </StyledText>
                      <StyledText
                        as="label"
                        htmlFor="name"
                        color={theme.danger}
                        className="mt-2"
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      name="postalCode"
                      id="postalCode"
                      placeholder={t("common:enterPostalCode")}
                    />
                  </div>

                  <div className="mt-4 col">
                    <div className="d-flex align-items-center mb-1">
                      <StyledText
                        as="label"
                        htmlFor="notes"
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className="ml-1"
                      >
                        <b className="fw-semibold">
                          {t("common:textAddNotes")}
                        </b>{" "}
                        &nbsp; ({t("common:DescriptionAddNotes")})
                      </StyledText>
                    </div>
                    <FormikInputField
                      key={i18n.language}
                      as="textarea"
                      rows={4}
                      name="notes"
                      id="notes"
                      placeholder={t("common:enterNotes")}
                    />
                  </div>
                </Row>

                <StyledButton
                  icon={isEdit ? <UpdateIcon className="me-2" /> : <AddIcon />}
                  className="mt-5 py-2 text-white d-inline-flex w-100"
                  type="submit"
                  loading={newLoading || updateLoading}
                  disabled={newLoading || updateLoading}
                >
                  {isEdit
                    ? t("common:textUpdateNominee")
                    : t("common:addNominee")}
                </StyledButton>

                {/* 

                <div className="d-block d-sm-flex align-items-center gap-2 gap-md-4">
                <div className='d-flex align-items-center'>
                        <StyledText
                          as='label'
                          htmlFor='firstName'
                          color={theme.headingTextColor}
                          className='ml-1'
                        >
                          {t("common:textFirstName")}
                        </StyledText>
                        
                        <StyledText
                          as='label'
                          htmlFor='firstName'
                          color={theme.danger}
                          className='mt-2'
                        >
                          *
                        </StyledText>
                      </div>
                  <div className="d-flex flex-column  w-100 mt-3 mt-sm-0">
                    <StyledText
                      $fontSize="16px"
                      $fontWeight={600}
                      className="mb-2"
                    >
                      {t("common:textFirstName")} *
                    </StyledText>
                    <FormikInputField
                                        key={i18n.language}
 name="firstName" />
                  </div>
                  <div className="d-flex flex-column w-100">
                    <StyledText
                      $fontSize="16px"
                      $fontWeight={600}
                      className="mb-2"
                    >
                      {t("common:textLastName")} *
                    </StyledText>
                    <FormikInputField
                                        key={i18n.language}
 name="lastName" />
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-center gap-2 gap-md-4 mt-4">
                  <div className="d-flex flex-column w-100">
                    <StyledText
                      $fontSize="16px"
                      $fontWeight={600}
                      className="mb-2"
                    >
                      {t("common:textEmail")} *
                    </StyledText>
                    <FormikInputField
                                        key={i18n.language}
 name="email" />
                  </div>
                  <div className="d-flex flex-column  w-100 mt-3 mt-sm-0">
                    <StyledText
                      $fontSize="16px"
                      $fontWeight={600}
                      className="mb-2"
                    >
                      {t("common:textPhoneNumber")} *
                    </StyledText>
                    <FormikInputField
                                        key={i18n.language}
 name="phoneNumber" />
                  </div>
                </div>
                <div className="d-block d-sm-flex align-items-center gap-2 gap-md-4 mt-4">
                  <div
                    className={`d-flex flex-column w-100  ${
                      isEdit ? "feature-pending" : ""
                    }`}
                  >
                    <StyledText
                      $fontSize="16px"
                      $fontWeight={600}
                      className="mb-2"
                    >
                      {t("common:textPersonalNoFull")} *
                    </StyledText>
                    <FormikInputField
                                        key={i18n.language}
 name="personalNumber" />
                  </div>
                  <div className="d-flex flex-column w-100">
                    <StyledText
                      $fontSize="16px"
                      $fontWeight={600}
                      className="mb-2"
                    >
                      {t("common:textPostalCode")}
                    </StyledText>
                    <FormikInputField
                                        key={i18n.language}
 name="postalCode" />
                  </div>
                </div>
                <div className="d-block align-items-center gap-2 gap-md-4 mt-4">
                  <div className="d-flex flex-column w-100">
                    <StyledText
                      $fontSize="16px"
                      $fontWeight={600}
                      className="mb-2"
                    >
                      {t("common:textAddNotes")}{" "}
                      <StyledText $fontSize="13px" color={theme.gray}>
                        ({t("common:DescriptionAddNotes")})
                      </StyledText>
                    </StyledText>
                    <FormikInputField
                                        key={i18n.language}
 name="notes" as="textarea" rows={4} />
                  </div>
                </div> */}
              </StyledContainer>
              {/* <div className="d-flex justify-content-end align-items-center ">
                <StyledButton
                  icon={isEdit ? <UpdateIcon className="me-2" /> : <AddIcon />}
                  className="mt-3 py-2 rounded-2 text-white d-inline-flex"
                  type="submit"
                  loading={newLoading || updateLoading}
                  disabled={newLoading || updateLoading}
                >
                  {isEdit
                    ? t("common:textUpdateNominee")
                    : t("common:textAddNominee")}
                </StyledButton>
              </div> */}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const StyledContainer = styled.div``;

export default AddNomineeModal;
