import React, { useState } from "react";
import DeleteModal from "../../Modals/DeleteModal";
import AddClientModal from "../../Modals/AddClientModal";
import {
  useAdminRequestDocumentMutation,
  useDeleteAIDocumentMutation,
  useDeleteClientMutation,
  useGetAdminRequestedDocsQuery,
  useGetAdminRequestStatusMutation,
  useGetAIDocumentsQuery,
  useGetUserListQuery,
} from "../../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { svenskaLs } from "../../../Services/Utils/localStorage";
import MenuSection from "../AdminClientWorkplace/MenuSection";
import { AdminCommonContainer } from "../../../Components/StyledComponents/CommonStyled";
import DocumentPostingModal from "../../Modals/DocumentPostingModal";
import PaymentModal from "../../Modals/PaymentModal";
import DocumentsTable from "./DocumentsTable";
import AIMenuSection from "./AIMenuSection";
import AIDocumentModal from "../../Modals/AIDocumentModal";

const AiDocument = () => {
  const { t } = useTranslation();
  const [deleteAIDocument, { isLoading: deleteLoading }] =
    useDeleteAIDocumentMutation();
  const lang = svenskaLs.getItem("lng") || "swe";
  const [deleteData, setDeleteData] = useState(false);
  const [docId, setDocId] = useState("");
  const [s3, setS3] = useState("");

  const [userDetails, setUserDetails] = useState("");

  const [timer, setSearchTimer] = useState(null);
  const [addClintModalOpen, setaddClintModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState({ data: {}, state: false });
  const [sorted, setSorted] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [getStatus, { isLoading: isLoadingStatus }] =
    useGetAdminRequestStatusMutation();
  const [requestDocument, { isLoading: requestLoading }] =
    useAdminRequestDocumentMutation();

  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  const [listing, setListing] = useState("");
  const {
    data: getAIDocuments,
    refetch: refetchData,
    isLoading,
  } = useGetAIDocumentsQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query && filters.query,
      sort: filters.sortedColumn.path || listing.sort,
      sortDir: filters.sortedColumn.order || listing.sortDir,
      ...listing,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handlePerPageChange = (perPage) => {
    setFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setFilters({ ...filters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };

  const handleSortBy = (item) => {
    setSorted(item.match);
    const prevFilters = { ...listing };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setListing({ ...prevFilters });
  };

  // const handleDelete = () => {
  //   let data = { documentId: docId, s3Key: s3 };

  //   deleteAIDocument(data)
  //     .unwrap()
  //     .then((response) => {
  //       // Handle success case
  //       if (getAIDocuments?.data?.length === 1 && getAIDocuments?.page > 1) {
  //         setFilters((prevState) => ({
  //           ...prevState,
  //           page: prevState.page - 1,
  //         }));
  //       } else {
  //         refetchData(); // Refetch the current page
  //       }
  //       setDeleteData(false);
  //       toast.success(t("common:documentDelete"));
  //     })
  //     .catch((error) => {
  //       toast.error(t("common:failedToDeleteDocument"));
  //     });
  // };

  const handleDelete = async () => {
    try {
      let data = { documentId: docId, s3Key: s3 };
      let response = await deleteAIDocument(data).unwrap(); // Use `unwrap` to throw errors locally
      // Success logic

      if (response?.deleted_files?.length) {
        if (getAIDocuments?.data?.length === 1 && getAIDocuments?.page > 1) {
          setFilters((prevState) => ({
            ...prevState,
            page: prevState.page - 1,
          }));
        } else {
          refetchData();
        }
        setDeleteData(false);
        toast.success(t("common:documentDelete"));
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      // Error is now caught locally
      toast.error(t("common:failedToDeleteDocument"));
    }
  };
  return (
    <AdminCommonContainer>
      <AIMenuSection
        sorted={sorted}
        handleSortBy={handleSortBy}
        handleSearch={handleSearch}
        setAddClientModal={setaddClintModalOpen}
        setIsEdit={setIsEdit}
      />
      <DocumentsTable
        setS3={setS3}
        clientDetails={getAIDocuments}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
        setIsEdit={setIsEdit}
        setaddClientModalOpen={setaddClintModalOpen}
        setDeleteData={setDeleteData}
        setDocId={setDocId}
        loadClients={isLoading}
        refetchData={refetchData}
        loader={isLoadingStatus}
      />
      {/* <StyledTableSkeleton cols={column} isFilter={true} /> */}
      <DeleteModal
        show={deleteData}
        onHide={() => setDeleteData(false)}
        handleDelete={handleDelete}
        disabled={deleteLoading}
        loading={deleteLoading}
      />
      {addClintModalOpen && (
        <AIDocumentModal
          show={addClintModalOpen}
          onHide={() => setaddClintModalOpen(false)}
          refetchData={refetchData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
    </AdminCommonContainer>
  );
};

export default AiDocument;
