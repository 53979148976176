import React, { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Formik, Form } from "formik";
import FormikInputField from "../../Components/Formik/FormikInputField";
import AddIcon from "../../Components/Svgicons/AddIcon";
import * as Yup from "yup";
import {
  useChatBotPreSignedUrlMutation,
  useDocumnetPreSignedUrlMutation,
  usePostNewClientMutation,
  useUpdateClientMutation,
  useUploadAIDocumentMutation,
  useUploadDocumentMutation,
  useUploadAIDocumentToModelMutation,
} from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../Components/Svgicons/CrossIcon";
import { toast } from "react-toastify";
import UpdateIcon from "../../Components/Svgicons/UpdateIcon";
import { svenskaLs } from "../../Services/Utils/localStorage";
import {
  fonts,
  formatFileSize,
  getIconByFileExtension,
} from "../../Services/Utils/helper";
import FormikDropZone from "../../Components/Formik/FormikDropZone";
import { isArray } from "lodash";
import DeleteIcon from "../../Components/Svgicons/DeleteIcon";
import styled from "styled-components";
import DocUploadIcon from "../../Components/Svgicons/DocUploadIcon";

const AIDocumentModal = ({ show, onHide, refetchData }) => {
  const [chatBotPreSignedUrl, { isLoading }] = useChatBotPreSignedUrlMutation();
  const [uploadAIDocument, { isLoading: loading }] =
    useUploadAIDocumentMutation();
  const [files, setFiles] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [uploadAIDocumentToModel, { isLoading: uploadToModelLoading }] =
    useUploadAIDocumentToModelMutation();

  const { t } = useTranslation();

  const handleUploadFile = async (acceptedFiles, setFieldValue) => {
    const pdfFiles = acceptedFiles.filter(
      (file) => file.type === "application/pdf"
    );

    if (pdfFiles.length !== acceptedFiles.length) {
      toast.error("Only PDF files are allowed");
      return;
    }
    setFiles(acceptedFiles);
    const uploadedFiles = []; // Array to store uploaded files
    setIsLoading(true);
    for (const file of acceptedFiles) {
      try {
        // Get presigned URL for uploading the file
        const {
          data: { response: responseData },
        } = await chatBotPreSignedUrl({
          fileName: file.name,
          fileType: file.type,
        });

        if (!responseData?.url) {
          toast.error(
            "Could not process the upload. Please make sure the file is valid and the internet connection is stable"
          );
          return;
        }

        // Create an upload request using the fetch API
        const uploadRequest = new Request(responseData?.url, {
          method: responseData?.method,
          headers: {
            Accept: "application/json",
            "Content-Type": file.type,
          },
          body: file,
        });

        // Upload the file
        const uploadResponse = await fetch(uploadRequest);

        if (uploadResponse.ok) {
          // Add file to uploadedFiles array upon successful upload
          const uploadedFile = { file, key: responseData.key };
          uploadedFiles.push(uploadedFile);

          // Update form field with the current array of uploaded files
          setFieldValue("document", uploadedFiles);
        } else {
          toast.error("File upload failed");
        }
      } catch (error) {
        toast.error("File upload encountered an error");
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  // const handleUpload = () => {
  //   const formData = new FormData();
  //   files.forEach((file, index) => {
  //     formData.append("files", file);
  //   });

  //   uploadAIDocumentToModel(formData);
  // };

  // const handleSubmit = (values) => {
  //   let document = values?.document?.map((item) => ({
  //     originalFilename: item?.file?.name,
  //     s3Key: item?.key,
  //   }));

  //   let payload = { ...values, document };
  //   handleUpload();
  //   uploadAIDocument(payload).then((Resp) => {
  //     onHide();
  //     if (Resp?.error?.status === 400) {
  //       toast.error(Resp?.error?.data?.message);
  //     } else {
  //       toast.success(t("common:aiFileToast", { count: files?.length }));
  //       refetchData();
  //     }
  //   });
  // };

  const handleUpload = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file); // Append all files to FormData
    });

    try {
      const response = await uploadAIDocumentToModel(formData).unwrap(); // Wait for API response
      return response; // Return response if successful
    } catch (error) {
      throw new Error("File upload failed. Please try again.");
    }
  };

  // Function to handle form submission
  const handleSubmit = async (values) => {
    try {
      // Prepare the document payload
      const document = values?.document?.map((item) => ({
        originalFilename: item?.file?.name,
        s3Key: item?.key,
      }));
      const payload = { ...values, document };

      // Step 1: Handle file upload
      // await handleUpload(); // If file upload fails, it will throw an error

      // Step 2: Handle the second API call
      await uploadAIDocument(payload).unwrap();
      toast.success(t("common:aiFileToast", { count: files?.length }));
      refetchData(); // Refresh data
      onHide(); // Close modal or reset form
    } catch (error) {
      // Handle errors from either API call
      toast.error(error?.message || "An error occurred. Please try again.");
    }
  };

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="d-flex flex-column bg-body rounded p-3 p-sm-5">
        <div className="d-flex align-item-center justify-content-between">
          <div className="d-flex flex-column justify-content-center align-items-center w-100 pb-2">
            <StyledText
              as="h1"
              $fontSize="20px"
              $fontWeight={600}
              color={theme.secondary}
            >
              {t("common:uploadAiDocument")}
            </StyledText>
            <StyledText
              as="p"
              $fontSize="14px"
              $fontWeight={400}
              color={theme.gray}
            >
              {t("common:drag_and_drop_select_multiple_files")}
            </StyledText>
          </div>
          <div onClick={onHide} className="cursor-pointer d-block d-md-none">
            <CrossIcon />{" "}
          </div>
        </div>
        <Formik
          initialValues={{
            document: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikDropZone
                name="document"
                isShowError
                buttonHeading={t("admin:dragAndDropFile")}
                buttonDescription={t("common:fileSupported")}
                setFiles={setFiles}
                setFieldValue={setFieldValue}
                selectedFiles={values?.document}
                onDrop={(val) => handleUploadFile(val, setFieldValue)}
              />
              <StyledButton
                icon={<DocUploadIcon />}
                className={`mt-3 py-2 text-white d-inline-flex w-100 mt-5 ${
                  files.length === values?.document?.length
                    ? ""
                    : "feature-pending"
                }`}
                type="submit"
                disabled={
                  loading ||
                  isloading ||
                  isLoading ||
                  uploadToModelLoading ||
                  files?.length === 0
                }
                loading={
                  loading || isloading || isLoading || uploadToModelLoading
                }
              >
                <span className="d-block d-sm-none d-md-block">
                  {t("common:uploadFiles")}
                </span>
              </StyledButton>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AIDocumentModal;
