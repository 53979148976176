import { createSlice } from "@reduxjs/toolkit";
import { svenskaLs } from "../Utils/localStorage";

// import { apiSlice } from '../../app/services/apiSlice';

const lsToken = svenskaLs.getObject("token");
const lsAccount = svenskaLs.getObject("account");
const lsUserType = svenskaLs.getObject("userType");
// const AUTH_URL = 'auth/';

// export const extendedApiSlice = apiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     claimGrant: builder.query({
//       query: (data) => ({
//         url: `${AUTH_URL}claim-grant`,
//         method: 'POST',
//         body: {
//           ...data,
//         },
//       }),
//     }),
//     getUserLicence: builder.mutation({
//       query: () => ({
//         url: `company/licence`,
//       }),
//     }),
//   }),
// });

const slice = createSlice({
  name: "auth",
  initialState: {
    token: lsToken,
    account: lsAccount,
    userType: lsUserType,

  },
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          token: { accessToken },
          account,
          userType,
        },
      }
    ) => {
      state.token = accessToken;
      state.account = account;
      state.userType = userType;

      svenskaLs.setObject("token", accessToken);
      svenskaLs.setObject("userType", userType);

      // svenskaLs.setObject('refreshToken', refreshToken);
      // svenskaLs.setObject('expiry', expiresIn);
      svenskaLs.setObject("account", account);
    },
    removeCredentials: (state, action) => {
      state.token = undefined;
      state.account = undefined;
      svenskaLs.removeItem("token");
      svenskaLs.removeItem("refreshToken");
      svenskaLs.removeItem("expiry");
      svenskaLs.removeItem("account");
      svenskaLs.removeItem("userType");
      svenskaLs.removeItem('pageReload');
      svenskaLs.removeItem('userDetails');
      svenskaLs.removeItem("orderRef");

    },
  },
});

export const { setCredentials, removeCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => {
  return state?.auth?.account;
};

// export const { useClaimGrantQuery } = extendedApiSlice;
