import React, { useState } from 'react'
import StyledText from '../../../Components/StyledComponents/StyledText'
import theme from '../../../Services/Utils/themes'
import StyledIconInput from '../../../Components/StyledComponents/StyledIconInput'
import StyledAccordion from '../../../Components/StyledComponents/StyledAccordian'
import DocumentListing from '../../../Pages/DocumentPosting/DocumentListing'
import PaginationPerPage from '../../../Components/pagination/PaginationPerPage'
import { AddressContainer, DocumentListContainer, DocumentPostingStyledContainer, StyledContainer, StyledDocumentListContainer } from '../../../Components/StyledComponents/CommonStyled'
import { svenskaLs } from '../../../Services/Utils/localStorage'
import { formatAddress, USERTYPE, useWindowSize } from '../../../Services/Utils/helper'
import { useTranslation } from 'react-i18next'
import { useChangeStatusMutation, useGetAdminRequestedDocsQuery } from '../../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice'
import DocumentPostingSkeleton from '../../../Components/Skeletons/DocumentPostingSkeleton'
import SharedDocZeroState from '../../../Components/Svgicons/SharedDocZeroState'
const AdminDocumentPosting = () => {
    const { t } = useTranslation()
    const isSmallScreen = useWindowSize();

    const [changeStatus, { isLoading: statusLoading }] = useChangeStatusMutation();


    const initialFilters = {
        perPage: 5,
        page: 1,
        query: "",
        sortedColumn: { path: "createdAt", order: "DESC" },
    };
    const [timer, setSearchTimer] = useState(null);
    const [filters, setFilters] = useState({ ...initialFilters });
    const [isOpen, setIsOpen] = useState(false);
    const [isScrollEnabled, setIsScrollEnabled] = useState(false);
    

    const [selectedValue, setSelectedValue] = useState("Requested");
    const userType = svenskaLs.getObject("userType");

    const {
        data: getAdminRequestedDocs,
        refetch: refetchDocs,
        isLoading: documentLoads,
    } = useGetAdminRequestedDocsQuery(
        {
            perPage: filters.perPage,
            page: filters.page,
            query: filters.query && filters.query,
            sort: filters.sortedColumn.path || filters.sort,
            sortDir: filters.sortedColumn.order || filters.sortDir,
            ...filters,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );



    const handleSearch = (evt) => {
        clearTimeout(timer);
        const query = evt.target.value;
        const newTimer = setTimeout(() => {
            setFilters({ ...filters, page: 1, query: query });
        }, 1000);
        setSearchTimer(newTimer);
    };

    const handleChangeDocStatus = (dropdownValue, Document) => {

        let payload = {
            docId: Document?.id,
            statusId: dropdownValue?.value,
        }

        changeStatus(payload).then(() => {
            refetchDocs();
        })

    }

    const handlePerPageChange = (perPage) => {
        setFilters((prevState) => ({
            ...prevState,
            perPage: perPage.target.innerText,
            page: 1,
        }));
    };

    const handlePageChange = ({ selected }) => {
        setFilters((prevState) => ({
            ...prevState,
            page: selected + 1,
        }));
    };


    return (
        <div className="bg-white p-0 p-md-4 pt-3">
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-6 col-12'>
                        <div className='text-center text-md-start'>
                            <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary}>
                                {t('common:adminDocumentPosting')}
                            </StyledText>
                            <StyledText as='p' $fontWeight={400} $fontSize="13px" color={theme.doveGray}>
                                {t('common:allCurrentAndRecentRequest')}
                            </StyledText>
                        </div>

                    </div>
                    <div className='col-md-6 col-12 mb-3'>
                        <StyledIconInput onChange={handleSearch} placeholder={t('common:searchDocument')} $maxWidth={isSmallScreen ? '100%' : "360px"} className="d-flex justify-content-end" />

                    </div>

                </div>

            </div>
            <DocumentPostingStyledContainer className='p-2' height="287px" zeroState={!documentLoads && (getAdminRequestedDocs?.data?.length === 0 || getAdminRequestedDocs?.data?.length === undefined)} scrollEnabled={getAdminRequestedDocs?.data?.length >= 5 || isOpen === isScrollEnabled}>
                {
                    !documentLoads && (getAdminRequestedDocs?.data?.length === 0 || getAdminRequestedDocs?.data?.length === undefined) ? <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <div className="d-flex align-items-center justify-content-center">
                            <SharedDocZeroState />
                        </div>
                        <StyledText
                            color={theme.gray}
                            $fontWeight={600}
                            $fontSize="20px"
                            className="text-center mt-3"
                        >
                            {t('common:noDocumentPosted')}
                        </StyledText>
                        <StyledText
                            color={theme.gray}
                            $fontWeight={500}
                            $fontSize="14px"
                            className="text-center"
                        >
                            {t('common:allCurrentAndRecentRequest')}
                        </StyledText>
                    </div> : documentLoads ? <DocumentPostingSkeleton /> :

                        getAdminRequestedDocs?.data?.map((item, index) => (
                            <StyledAccordion
                                headerColor={theme.desertStormWhite}
                                key={index}
                                statusLoading={statusLoading}
                                index={index}
                                handleChangeDocStatus={handleChangeDocStatus}
                                data={item}
                                isOpen={isOpen === index}
                                setIsOpen={setIsOpen}
                                setSelectedValue={setSelectedValue}
                                selectedValue={selectedValue}
                                onToggle={() => { setIsOpen(isOpen === index ? null : index); setIsScrollEnabled(index) }}
                            >
                                <div className='px-0 px-md-5'>
                                    <StyledDocumentListContainer >
                                        {item?.clientDocPosting?.map((item, index, array) => (
                                            <DocumentListing file={item} key={index} index={index} totalFiles={array.length} />
                                        ))}

                                    </StyledDocumentListContainer>
                                    {userType === USERTYPE.ADMIN ? <hr className='mt-3' /> : null}
                                    <div className='container-fluid mt-0 mt-md-3'>
                                        <div className='row align-items-center'>
                                            <div className='col'>
                                                <div className='d-flex flex-column mt-3 mt-md-0'>
                                                    <StyledText>
                                                        <b>{t('common:name')}</b>&nbsp;:&nbsp;{item?.name}
                                                    </StyledText>
                                                    <StyledText className="text-truncate">
                                                        <b>{t('common:textEmailAddress')}</b>&nbsp;:&nbsp;{item?.email}
                                                    </StyledText>
                                                    <StyledText><b>{t('common:phone')}</b>&nbsp;:&nbsp;{item?.phoneNumber}</StyledText>

                                                </div>
                                            </div>
                                            <div className='col'>
                                                <AddressContainer>
                                                    <StyledText>
                                                        <b className='fw-semibold'>{t('common:requestedAddress')}</b> {formatAddress(item)}
                                                    </StyledText>
                                                </AddressContainer>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </StyledAccordion>
                        ))}

            </DocumentPostingStyledContainer>

            {documentLoads ? null : !documentLoads && (getAdminRequestedDocs?.data?.length === 0 || getAdminRequestedDocs?.data?.length === undefined) ? null : <div className='px-3'>
                <PaginationPerPage
                    perPage={getAdminRequestedDocs?.perPage || 0}
                    pageNo={getAdminRequestedDocs?.page || 0}
                    onPerPageChange={handlePerPageChange}
                    totalCount={getAdminRequestedDocs?.count || 0}
                    onPageChange={handlePageChange}
                />
            </div>}


        </div>
    )
}







export default AdminDocumentPosting