import styled, { keyframes } from 'styled-components';

const l5Animation = keyframes`
  0%   { box-shadow: 8px 0 #000, -8px 0 #0002; background: #000; }
  33%  { box-shadow: 8px 0 #000, -8px 0 #0002; background: #0002; }
  66%  { box-shadow: 8px 0 #0002, -8px 0 #000; background: #0002; }
  100% { box-shadow: 8px 0 #0002, -8px 0 #000; background: #000; }
`;

const Loader = styled.div`
  width: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: ${l5Animation} 1s infinite linear alternate;
`;

export default Loader;
