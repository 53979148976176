import React, { useEffect, useRef } from "react";
import {
  StyledChatView,
  StyledLeftMessageView,
  StyledRighMessageView,
} from "../../../Components/Chat/StyledChatBox";
import StyledText from "../../../Components/StyledComponents/StyledText";
import theme from "../../../Services/Utils/themes";
import styled from "styled-components";
import Markdown from "markdown-to-jsx";
import Loader from "../../../Components/StyledComponents/StyledLoader";
import Logo from "../../../Components/Svgicons/Logo";
import StyledDot from "../../../Components/StyledComponents/StyledDot";
import ChatLimitExceedModal from "../../../Components/Modals/ChatLimitExceedModal";
import InfoIcon from "../../../Components/Svgicons/InfoIcon";
import { useTranslation } from "react-i18next";
import { fonts } from "../../../Services/Utils/helper";

const ChatView = ({
  message,
  isAnswer,
  waitingAnswer,
  lsToken,
  show,
  responses,
  handleNewChat,
}) => {
  const messageEndRef = useRef(null);
  const {t} = useTranslation();
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message]);
  return (
    <>
      {message?.map((item, index) => {       
        return (
          <>
            {item?.senderType === "USER" && (
              <StyledMessageContainer className="align-self-end">
                <StyledRighMessageView className="bg-primary p-3 d-flex flex-column me-0 mt-3">
                  <StyledText
                    as="label"
                    color={theme.white}
                    $fontWeight={400}
                    $fontSize="13px"
                    className="mb-0"
                  >
                    {item?.message || ""}
                  </StyledText>
                </StyledRighMessageView>
              </StyledMessageContainer>
            )}
            {item?.senderType === "AGENT" &&
            !item?.message?.includes("error") ? (
              <div className="d-flex w-100 mt-3 gap-2">
                <Logo />
                <StyledMessageContainer className="align-self-start ">
                  <StyledLeftMessageView className="bg-light-gray p-3 d-flex flex-column ms-0">
                    <StyledText
                    color={theme.blackTone}

                      $fontWeight={400}
                      $fontSize="14px"
                      $fontFamily={fonts.quicksand}
                    >
                      <Markdown>{item?.message || ""}</Markdown>
                    </StyledText>
                  </StyledLeftMessageView>
                </StyledMessageContainer>
              </div>
            ) : item?.senderType === "ERROR" ? (
              <div className="d-flex w-100 mt-3 gap-2">
                <Logo />
                <StyledMessageContainer className="align-self-start ">
                  <StyledLeftMessageView className="bg-lightRed p-3 d-flex flex-column ms-0 border border-danger">
                    <StyledText
                      color={theme.danger}
                      $fontWeight={400}
                      $fontSize="13px"
                      $fontFamily={fonts.montserrat}
                    >
                        {item?.message}
                    </StyledText>
                  </StyledLeftMessageView>
                </StyledMessageContainer>
              </div>
            ) : null}
          </>
        );
      })}

      {isAnswer && (
        <StyledMessageContainer className="align-self-start mt-3">
          <StyledLeftMessageView className="bg-light-gray p-3 d-flex flex-column ms-0 ">
            <Loader />
          </StyledLeftMessageView>
        </StyledMessageContainer>
      )}
      <div ref={messageEndRef} />

      {lsToken
        ? null
        : !show && (
            <div className="d-flex align-items-center align-self-start gap-0 gap-md-3 flex-wrap ">
              <div className="d-flex align-items-center align-self-start   ms-5 p-2 gap-2 bg-aliceblue mt-3">
                <StyledDot color={theme.success} />
                <StyledText>{t("common:2of5Responses", { response: responses?.length })}</StyledText>
              </div>
              <div className="d-flex align-items-center align-self-start p-2 ms-5 ms-md-0 gap-2 bg-aliceblue mt-3">
                <InfoIcon />
                <StyledText color={theme.greenishGray}>
                  {t("common:AiGeneratedContent")}
                </StyledText>
              </div>
            </div>
          )}

      {show && (
        <ChatLimitExceedModal show={show} handleNewChat={handleNewChat} />
      )}
    </>
  );
};

const StyledMessageContainer = styled.div`
  max-width: 80% !important;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default ChatView;
