const HandShakeIcon = ({ size = 21, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1085_33807)">
        <path fillRule="evenodd" clipRule="evenodd" d="M25.3896 37.5768C25.4384 37.7382 24.8261 37.2707 24.7651 37.2288C24.3883 36.9663 24.0203 36.6916 23.6513 36.4186C22.5594 35.6092 21.491 34.765 20.4304 33.9146C19.6019 33.2509 18.7829 32.5767 17.9718 31.8929C17.2706 31.3016 16.5737 30.7024 15.8864 30.0936C15.4538 29.7107 15.0273 29.3209 14.6087 28.9232C13.2752 27.6568 9.77515 24.6173 11.0145 22.3366C11.53 21.3885 13.3763 22.1717 14.9977 23.5253C15.1904 23.6858 19.9813 28.2115 20.1862 27.867C20.3118 27.655 20.0955 27.1483 19.9629 27.0349C19.3646 26.5256 11.8614 18.3891 10.9046 17.1306C10.0787 16.0447 9.05561 14.378 10.5557 13.0201C11.3721 12.2805 12.4972 12.5125 13.2385 13.2102C15.5009 15.34 18.1584 18.3604 20.0894 20.4492C20.7758 21.1914 22.4556 22.9279 22.6614 22.6933C22.8812 22.4421 22.4783 21.8377 22.3518 21.6336C21.7954 20.7318 20.959 20.034 20.2691 19.2395C19.2661 18.0839 18.1698 16.9056 17.056 15.6627C15.685 14.1321 14.2869 12.5029 13.0004 10.6949C12.2338 9.61687 11.6756 7.2437 13.3188 6.50498C14.6593 5.90144 16.2501 7.17044 17.1136 8.05918C17.2235 8.17256 17.3421 8.27984 17.4441 8.40281C19.4283 10.7751 21.941 13.6088 23.9767 16.2157C24.4773 16.8567 25.7437 18.4798 26.1431 18.1475C26.6525 17.7237 25.4785 16.2698 24.9073 15.4892C23.9304 14.1539 20.7889 10.8117 19.5687 8.26763C18.7829 6.62795 19.2486 5.47233 19.6577 5.12085C21.2625 3.7437 23.3557 6.19013 24.2392 7.2594C26.3891 9.85933 28.2948 12.6808 30.2606 15.4194C31.1162 16.6117 32.1855 18.54 33.5941 19.168C34.3799 19.5186 34.7471 18.7563 34.7645 18.0943C34.8081 16.4294 34.6424 15.0662 35.6131 13.5774C37.0278 11.4066 40.8461 9.96661 42.5556 12.7105C43.9031 14.8743 40.4973 16.8018 40.588 18.9534C40.758 22.9924 42.2503 26.707 40.9944 30.7905C39.5963 35.3354 33.9351 38.5327 29.3213 38.2911C27.2839 38.1847 25.3904 37.3396 23.6025 36.4238" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.9581 1.16075C30.078 1.71458 31.1533 2.87456 32.0883 4.24735C33.537 6.37457 34.6342 9.02247 34.9438 10.9142C34.9987 11.2474 35.3136 11.4741 35.6468 11.4192C35.9799 11.3642 36.2067 11.0494 36.1517 10.7162C35.8194 8.68668 34.6534 5.83993 33.1 3.55834C32.0334 1.99192 30.7792 0.695011 29.5006 0.0635615C29.1979 -0.0864514 28.8299 0.0382687 28.6807 0.340911C28.5307 0.643553 28.6555 1.01161 28.9581 1.16075Z" fill="#C77B21" />
        <path fillRule="evenodd" clipRule="evenodd" d="M26.8326 4.65671C27.7065 5.04308 28.5534 5.84286 29.2921 6.78393C30.4373 8.2422 31.319 10.0485 31.5781 11.3358C31.6452 11.6672 31.9679 11.8818 32.2993 11.8155C32.6308 11.7492 32.8453 11.4256 32.779 11.0942C32.4912 9.66383 31.5283 7.64825 30.2558 6.02776C29.3828 4.91575 28.3624 3.99387 27.3289 3.53685C27.0201 3.39992 26.6582 3.54034 26.5212 3.84909C26.3843 4.15783 26.5247 4.51978 26.8335 4.65671H26.8326Z" fill="#C77B21" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.18028 28.6123C1.6434 29.7723 2.71442 30.9366 4.00872 31.9771C6.01383 33.5898 8.56667 34.8936 10.4279 35.3533C10.7558 35.4344 10.9564 35.7667 10.8762 36.0946C10.7951 36.4226 10.4628 36.6232 10.1348 36.542C8.13843 36.0493 5.39372 34.6617 3.24208 32.9313C1.7655 31.7434 0.572379 30.3898 0.0438458 29.0658C-0.0817464 28.7518 0.0717552 28.3951 0.385736 28.2704C0.699716 28.1448 1.05643 28.2983 1.18115 28.6123H1.18028Z" fill="#C77B21" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.83856 26.7698C5.15428 27.6725 5.88516 28.5795 6.76343 29.3906C8.12576 30.6483 9.85701 31.6705 11.1199 32.0307C11.4452 32.1231 11.6336 32.4624 11.5403 32.7877C11.4478 33.1122 11.1086 33.3006 10.7833 33.2081C9.37993 32.8078 7.44721 31.6879 5.93313 30.2907C4.89437 29.3322 4.05622 28.2411 3.68293 27.1745C3.5713 26.8552 3.73962 26.5064 4.05797 26.3939C4.37718 26.2822 4.72692 26.4506 4.83768 26.7698H4.83856Z" fill="#C77B21" />
      </g>
      <defs>
        <clipPath id="clip0_1085_33807">
          <rect width="42.8723" height="38.2978" fill="white" />
        </clipPath>
      </defs>
    </svg>


  );
};

export default HandShakeIcon;
