import React from 'react'
import theme from '../../../Services/Utils/themes';
import StyledText from '../StyledText';
import { formatFileSize, getIconByFileExtension } from '../../../Services/Utils/helper';
import { isEmpty } from 'lodash';
import DeleteIcon from '../../Svgicons/DeleteIcon';
import styled from 'styled-components';

const FileMapping = ({ selectedFiles, removeFile }) => {

    return !isEmpty(selectedFiles) && (
        Array.isArray(selectedFiles) ? (
            <GridFileContainer selectedFiles={selectedFiles}>
                {selectedFiles?.map(({ file }) => (
                    <MultipleFileContainer key={file.path} selectedFiles={selectedFiles}>
                        <StyledImageCard>
                            <div className='d-flex gap-3 align-items-center'>
                            <div>{getIconByFileExtension(file?.path)}</div>
                            <div className='d-flex flex-column ml-2 text-truncate'>
                                <StyledText $fontSize={'13px'} $fontWeight={600} color={theme.secondary} truncatedlines={1} wordbreak>
                                    {file?.path}
                                </StyledText>
                                <StyledText $fontWeight={400} $fontSize={'12px'} color={theme.gray}>
                                    {formatFileSize(file?.size)}
                                </StyledText>

                            </div>
                            </div>

                            {/* Delete Button */}
                            <div className='d-flex flex-end'>
                                <StyledIcon
                                    color='white'
                                    className='cursor-pointer ml-2'
                                    background={theme?.danger}
                                    onClick={() => removeFile(file)}
                                    width={32}
                                    height={32}
                                >
                                    <DeleteIcon color={theme.white} />
                                </StyledIcon>
                            </div>
                        </StyledImageCard>
                    </MultipleFileContainer>
                ))}
            </GridFileContainer>

        ) : (
            <ImageContainer className='d-flex align-items-center flex-wrap justify-content-start'>
                <StyledImageCard>
                    <div className='d-flex gap-3 align-items-center'>
                    <div>{getIconByFileExtension(selectedFiles?.path)}</div>
                    <div className='d-flex flex-column ml-2 text-truncate'>
                        <StyledText $fontSize={'13px'} $fontWeight={600} color={theme.secondary} truncatedlines={1}>
                            {selectedFiles?.path}
                        </StyledText>
                        <StyledText $fontWeight={400} $fontSize={'12px'} color={theme.gray}>
                            {formatFileSize(selectedFiles?.size)}
                        </StyledText>

                    </div>
                    </div>

                    {/* Delete Button */}
                    <div className='d-flex flex-end'>
                        <StyledIcon
                            color='white'
                            className='cursor-pointer ml-2'
                            background={theme?.danger}
                            onClick={() => removeFile()}
                            width={32}
                            height={32}
                        >
                            <DeleteIcon color={theme.white} />
                        </StyledIcon>
                    </div>
                </StyledImageCard>
            </ImageContainer>
        )
    )

}

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  border-radius: 50px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
`;

export const StyledImageCard = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  height: 67px;
  padding: 0 10px;
  width: 100%;
`;


export const ImageContainer = styled.div`
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
`;


const GridFileContainer = styled.div`
  display: grid;
   grid-template-columns: ${({ selectedFiles }) => 
    selectedFiles?.length > 1 ? 'repeat(auto-fill, minmax(250px, 1fr))' : 'repeat(auto-fill, minmax(100%, 1fr))'};
  gap: 10px; 
  gap: 10px;
  justify-content: center;
`;

const MultipleFileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ selectedFiles }) => selectedFiles?.length > 1 ? '370px' : '100%'}   /* This limits the width of each item */
  width: 100%; 
   height: 67px;      /* Ensures the container takes up full width up to 370px */
`;


export default FileMapping