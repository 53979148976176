import React, { useEffect, useState } from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import MonitorIcon from "../../Components/Svgicons/MonitorIcon";
import MonitortabIcon from "../../Components/Svgicons/MonitortabIcon";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import VerifiedIcon from "../../Components/Svgicons/VerifiedIcon";
import { svenskaLs } from "../../Services/Utils/localStorage";
import { useDispatch } from "react-redux";
import {
  useGetAdminOrderRefMutation,
  useGetAdminQrCodeDataMutation,
} from "../../Services/Admin Api/loginApi/adminloginApiSlice";
import { setAdminCredentials } from "../../Services/Auth/adminAuthSlice";
import { useTranslation } from "react-i18next";
import StyledLanguageSwitch from "../../Components/StyledComponents/StyledLanguageSwitch";
import Skeleton from "react-loading-skeleton";
import {
  AuthContainer,
  CarouselContainer,
  QRContainer,
  StyledBgContainer,
  StyledCardContainer,
  StyledSelectOption,
} from "../../Components/StyledComponents/CommonStyled";
import Carousel from "../../Components/Carousel/Carousel";
import svenskaLogo from "../../Assets/Images/svenskaLogo.png";
import { useWindowSize } from "../../Services/Utils/helper";
import { toast } from "react-toastify";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StarsIcon from "../../Components/Svgicons/StarsIcon";
import { StyledIconBg } from "../../modules/authentication/AuthForm";
import KeyIcon from "../../Components/Svgicons/key";

function AdminLoginContainer({ handleSubmit, QrData }) {
  const { t } = useTranslation();
  const path = "/signup";
  const [activeTab, setActiveTab] = useState("");
  const [otherDevice, setOtherDevice] = useState(null);
  const [response, setResponse] = useState({});
  const [newResp, setNewResp] = useState({});
  const [verified, setVerified] = useState(false);
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useWindowSize();
  const [qrGenerating] = useGetAdminQrCodeDataMutation();
  const [orderRefCollect] = useGetAdminOrderRefMutation();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const handleQrCode = () => {
    QrData().then(({ data }) => {
      setResponse(data);
    });
    setOtherDevice(2);
  };

  useEffect(() => {
    if (otherDevice === 2 && response?.orderRef && !status) {
      let dataStatus = false;
      const pollData = async () => {
        for (let seconds = 0; !dataStatus && seconds < 20; seconds++) {
          try {
            const { data } = await qrGenerating(response.orderRef);

            if (data?.status === "complete") {
              dataStatus = true;
            } else if (data?.hintCode === "userSign") {
              setVerified(true);
            } else if (
              seconds === 19 &&
              data?.hintCode === "outstandingTransaction"
            ) {
              setOtherDevice(null);
            }

            setNewResp(data);
          } catch (error) {
            // Handle any errors here
            console.error("Error polling data:", error);
          }

          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before the next iteration
        }
      };

      pollData();
    }
    // eslint-disable-next-line
  }, [response, otherDevice, status]);

  const Tabs = [
    {
      id: 1,
      title: t("common:BankIdOnThisDevice"),
      icon: <MonitorIcon />,
      action: () => {
        handleSubmit();
      },
    },
    {
      id: 2,
      title: t("common:BankIdOnOtherDevice"),
      icon: <MonitortabIcon />,
      action: () => {
        handleQrCode();
      },
    },
  ];
  const handleRequestSocket = () => {
    window.open("/public/svenska-ai", "blank");
    // nav("/public/svenska-ai");
  };

  useEffect(() => {
    if (newResp?.status === "complete") {
      orderRefCollect(response?.orderRef).then((resp) => {
        setStatus(true);
        if (resp?.data?.token) {
          dispatch(
            setAdminCredentials({
              token: { accessToken: resp?.data?.token },
              account: resp?.data?.userInfo,
              userType: "admin",
            })
          );
          svenskaLs.removeItem("orderRef");
          navigate("/user-workplace");
        } else if (resp?.error?.status === 404) {
          toast.error(t("common:adminNotFound"), {
            autoClose: 5000,
          });
          navigate("/admin-login");
        } else {
          toast.error(resp?.error?.data?.message);
          navigate("/admin-login");
        }
      });
    }
    // eslint-disable-next-line
  }, [newResp?.status]);

  const slides = [
    {
      id: 1,
      title: t("common:sliderHeading1"),
      content: t("common:sliderDescription1"),
    },
    {
      id: 2,
      title: t("common:sliderHeading2"),
      content: t("common:sliderDescription2"),
    },
    {
      id: 3,
      title: t("common:sliderHeading3"),
      content: t("common:sliderDescription3"),
    },
  ];

  return (
    <StyledBgContainer>
      <div className="d-none d-md-block mb-5">
      <div className="d-flex justify-content-center w-100">
        <img src={svenskaLogo} alt="svenskaLogo" width={191} />
      </div>
      </div>
      <StyledCardContainer>
        <div className="d-block d-md-none w-100 mb-2">
          <div className="d-flex  align-items-center justify-content-end">
            <div className=" w-100">
              <img src={svenskaLogo} alt="svenskaLogo" width={140} />
            </div>

            <StyledLanguageSwitch />
          </div>
        </div>
        <CarouselContainer path={!path.includes("login")}>
          <Carousel slides={slides} />
        </CarouselContainer>

        <AuthContainer>
          <div className="d-none d-md-block">
            <div className="d-flex justify-content-end align-items-center gap-3  w-100">
              <div className="me-2">
                <div className="d-none d-md-block">
                  <StyledButton
                    icon={<StarsIcon />}
                    variant="white"
                    onClick={handleRequestSocket}
                  >
                    <StyledText
                      $fontWeight={500}
                      $fontSize="13px"
                      color={theme.secondary}
                    >
                      {t("common:trySevenskaAi")}
                    </StyledText>
                  </StyledButton>
                </div>

                <div className="d-block d-md-none">
                  <StyledButton variant="white" onClick={handleRequestSocket}>
                    <StarsIcon />
                  </StyledButton>
                </div>
              </div>
              <StyledLanguageSwitch />
            </div>
          </div>
          <QRContainer>
            <div className="d-none d-md-block w-100">
              <div className="d-flex justify-content-center">
              <StyledIconBg path={!path.includes("login")}>
                <KeyIcon/>
              </StyledIconBg>
              </div>
            </div>
            {verified ? null : (
              <div className="mt-4 ms-2">
                <StyledText
                  as="h1"
                  $fontWeight={600}
                  $fontSize={isSmallScreen ? "18px" : "24px"}
                  color={theme.oxfordBlue}
                >
                  {otherDevice
                    ? t("common:ScanQrCodeToLogin")
                    : t("admin:LoginScreenTitle")}
                </StyledText>

                <StyledText
                  as="p"
                  $fontWeight={400}
                  $fontSize={isSmallScreen ? "14px" : "16px"}
                  color={theme.doveGray}
                  className="mt-3"
                // onClick={() => setVerified(true)}
                >
                  {otherDevice
                    ? t("common:ScanQrCodeDesc")
                    : t("admin:LoginScreenDesc")}
                </StyledText>
              </div>
            )}

            {!otherDevice ? (
              <StyledSelectOption className="mt-4 mb-1">
                <div>
                  {Tabs?.map((item, index) => (
                    <div
                      className={`${item?.id === 1 ? "d-md-none" : "d-none d-md-block"
                        }`}
                    >
                      <div
                        className={` d-flex flex-column align-items-center border rounded py-4 px-5 mb-3 cursor-pointer  ${activeTab === item?.id ? "border border-primary" : ""
                          }`}
                        key={index}
                        onMouseEnter={() => setActiveTab(item?.id)}
                        onMouseLeave={() => setActiveTab(null)}
                        onClick={item?.action}
                      >
                        {item?.icon}
                        <StyledText
                          as="label"
                          $fontWeight={600}
                          $fontSize="14px"
                          color={theme.gray}
                          className="text-center mt-3 cursor-pointer"
                        >
                          {item?.title}
                        </StyledText>
                      </div>
                    </div>
                  ))}
                </div>
              </StyledSelectOption>
            ) : verified ? (
              <div className="mt-5 d-flex align-items-center justify-content-center flex-column">
                <VerifiedIcon size="100" />
                <StyledText
                  as="h1"
                  $fontWeight={700}
                  $fontSize="18px"
                  lh="1.5"
                  color={theme.oxfordBlue}
                  className="text-center px-4 mt-5"
                >
                  {t("common:TypeYourSecurityCode")}
                </StyledText>
              </div>
            ) : (
              <>
                <div className="border rounded p-3 mt-3 mb-4">
                  {newResp?.qrData ? (
                    <QRCode value={newResp?.qrData} />
                  ) : (
                    <Skeleton height={200} width={200} />
                  )}
                </div>
                <div
                  className="d-flex align-items-center gap-1 text-decoration-underline cursor-pointer"
                  onClick={() => {
                    setOtherDevice(null);
                  }}
                >
                  {/* <StyledText
                    $fontWeight={600}
                    $fontSize="16px"
                    color={theme.gray}
                  >
                    {t("common:OR")} -{" "}
                    <b className="fw-normal">{t("common:StartBankIDApp")}</b>
                  </StyledText> */}
                </div>
              </>
            )}
          </QRContainer>
        </AuthContainer>
      </StyledCardContainer>
    </StyledBgContainer>
  );
}

export default AdminLoginContainer;
