import "../src/Assets/Styles/importer.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Profile from "./Pages/Profile/Profile";
import RedirectPage from "./Pages/RedirectPage";
import AdminChat from "./Admin/AdminChat/AdminChat";
import SingleAdminView from "./Admin/AdminDashboard/SingleAdminView";
import AdminProtectedRoute from "./Routes/AdminProtectedRoute";
import { AdminLogin } from "./Services/Auth/AdminLogin";
import AdminRedirectPage from "./Pages/AdminRedirectPage";
import AdminProfile from "./Admin/AdminProfile/AdminProfile";
import StyledToastContainer from "./Components/StyledComponents/StyledToastContainer";
import SessionTimeoutWrapper from "./SessionTimeoutWrapper";
import Signup from "./Pages/Signup/Signup";
import Login from "./Pages/Login/Login";
import SignupRedirectPage from "./Pages/SignupRedirectPage";
import UserLogin from "./Pages/ProductionLogin/UserLogin";
import AdminSignin from "./Pages/ProductionLogin/AdminSignin";
import SharedDocument from "./Pages/SharedDocument/SharedDocument";
import ClientResources from "./Pages/ClientResources/ClientResources";
import DocumentPosting from "./Pages/DocumentPosting/DocumentPosting";
import AdminWorkplace from "./Admin/AdminDashboard/AdminWorkplace/AdminWorkplace";
import Resources from "./Admin/AdminDashboard/Resources";
import ClientWorkplace from "./Admin/AdminDashboard/AdminClientWorkplace/ClientWorkplace";
import AdminDocumentPosting from "./Admin/AdminDashboard/AdminDocumentPosting/AdminDocumentPosting";
import NomineeDocument from "./Pages/SharedDocument/NomineeDocument";
import Team from "./Pages/Team/Team";
import SvenskaAi from "./Pages/AI/SvenkaAi/SvenskaAi";
import CombinedProtectedRoute from "./Routes/CombinedProtectedRoute";
import { useEffect } from "react";
import socketService from "./Middleware/socket";
import { svenskaLs } from "./Services/Utils/localStorage";
import AiDocument from "./Admin/AdminDashboard/AIDocuments/AiDocument";

const App = () => {
  // useEffect(() => {
  //   const lsToken = svenskaLs.getObject("token");
  //   socketService.connect(lsToken);

  //   return () => {
  //     socketService.disconnect();
  //   };
  // }, []);

  return (
    <SessionTimeoutWrapper>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="myself/user-login" element={<UserLogin />} />
        <Route path="/public/svenska-ai" element={<SvenskaAi />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="myself/admin-Login" element={<AdminSignin />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="/signup/redirect" element={<SignupRedirectPage />} />
        <Route path="/adminRedirect" element={<AdminRedirectPage />} />

        <Route element={<AdminProtectedRoute />}>
          <Route path="/user-workplace" element={<ClientWorkplace />} />
          <Route path="/ai-document" element={<AiDocument />} />

          <Route path="/admin-workplace" element={<AdminWorkplace />} />
          <Route path="/admin-resources" element={<Resources />} />
          <Route path="/admin-document-posting" element={<AdminDocumentPosting />} />
          <Route path="/admin-chat/:id" element={<AdminChat />} />
          <Route path="/single-admin/:id" element={<SingleAdminView />} />
          <Route path="/admin-profile" element={<AdminProfile />} />

        </Route>

        <Route element={<CombinedProtectedRoute isAdmin={true} isClient={true} isNominee={true} />}>
          <Route path="/svenska-ai/*" element={<SvenskaAi />} />
        </Route>

        {/* <Route element={<ProtectedRoute noOutlet />}>
          <Route path="/svenska-ai" element={<SvenskaAi />} />
        </Route> */}

        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/shared-document/*" element={<SharedDocument />} />
          <Route path="/nominee-document/*" element={<NomineeDocument />} />

          <Route path="/resources/*" element={<ClientResources />} />
          <Route path="/team/*" element={<Team />} />

          <Route path="/document-posting/*" element={<DocumentPosting />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
      <StyledToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </SessionTimeoutWrapper>
  );
};

export default App;
