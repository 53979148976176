const AiStartIcon = ({ size = 20, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1085_35053)">
        <path d="M14.2333 12.898C14.2333 13.0982 14.1087 13.2778 13.9152 13.3522L10.9269 14.4606C9.87424 14.8506 9.05227 15.6639 8.66515 16.6928L7.5303 19.7229C7.52235 19.746 7.50379 19.7614 7.49318 19.7819C7.48258 19.7999 7.47462 19.8153 7.46136 19.8307C7.4428 19.8538 7.42424 19.8743 7.40303 19.8948C7.36856 19.9256 7.32879 19.9513 7.28636 19.9692C7.25985 19.9795 7.23333 19.9898 7.20417 19.9923C7.17765 20 7.15114 20.0026 7.12197 20.0026L7.03447 19.9923C7.00795 19.9898 6.98144 19.9795 6.95227 19.9692C6.9072 19.9513 6.86742 19.9231 6.83295 19.8923C6.81174 19.8743 6.79318 19.8538 6.77462 19.8307C6.76401 19.8179 6.75606 19.8025 6.74811 19.7896C6.73485 19.7665 6.71364 19.7486 6.70568 19.7229L5.57083 16.6928C5.18636 15.6665 4.36439 14.8506 3.31174 14.4606L0.323485 13.3522C0.129924 13.2803 0 13.1007 0 12.898C0 12.6876 0.143182 12.5003 0.35 12.4388L3.08371 11.6049C4.26629 11.2431 5.19962 10.3579 5.6 9.22132L6.71364 6.04236C6.72689 6.00644 6.74545 5.97309 6.76401 5.94486C6.77727 5.92434 6.79053 5.90638 6.81174 5.88842C6.85151 5.8448 6.90455 5.81401 6.96553 5.79092C6.98144 5.78322 7.00265 5.77809 7.02386 5.77552C7.05568 5.76526 7.0875 5.7627 7.12197 5.7627C7.15644 5.7627 7.19091 5.76526 7.22008 5.77552C7.24129 5.77809 7.25985 5.78579 7.27841 5.79092C7.33144 5.81144 7.37917 5.84223 7.42159 5.88072C7.4375 5.89611 7.45341 5.91407 7.46667 5.92947C7.49318 5.96539 7.51705 6.00131 7.5303 6.04493L8.64394 9.22389C9.04167 10.3605 9.97765 11.2457 11.1576 11.6075L13.8913 12.4413C14.1008 12.5029 14.2413 12.6902 14.2413 12.9006L14.2333 12.898Z" fill={color} />
        <path d="M19.0908 7.19425C19.0908 7.30458 19.0219 7.40208 18.9158 7.44313L17.2798 8.05121C16.7045 8.26417 16.2537 8.71061 16.0416 9.27251L15.4185 10.93C15.4185 10.93 15.4052 10.9505 15.3973 10.9633C15.392 10.9736 15.3867 10.9813 15.3814 10.989C15.3708 11.0018 15.3601 11.0121 15.3495 11.0223C15.331 11.0377 15.3098 11.0531 15.2859 11.0634C15.27 11.0685 15.2567 11.0737 15.2408 11.0762C15.2249 11.0788 15.2117 11.0814 15.1958 11.0814L15.148 11.0762C15.1321 11.0762 15.1189 11.0685 15.103 11.0634C15.0791 11.0531 15.0579 11.0377 15.0367 11.0223C15.0234 11.0121 15.0155 11.0018 15.0048 10.989C14.9995 10.9813 14.9942 10.9736 14.9889 10.9659C14.981 10.9531 14.9704 10.9428 14.9651 10.93L14.342 9.27251C14.1325 8.71061 13.6817 8.26417 13.1064 8.05121L11.4704 7.44313C11.3643 7.40465 11.2927 7.30458 11.2927 7.19425C11.2927 7.0788 11.3696 6.97617 11.4836 6.94281L12.9791 6.48611C13.6261 6.28855 14.1378 5.80362 14.3579 5.18271L14.9677 3.44057C14.9757 3.42004 14.9836 3.40208 14.9942 3.38669C15.0022 3.37642 15.0101 3.36616 15.0208 3.3559C15.042 3.3328 15.0711 3.31484 15.1056 3.30202C15.1162 3.29945 15.1268 3.29432 15.1374 3.29432C15.1533 3.28919 15.1719 3.28662 15.1905 3.28662C15.209 3.28662 15.2276 3.28662 15.2435 3.29432C15.2541 3.29432 15.2647 3.29945 15.2753 3.30202C15.3045 3.31228 15.331 3.33024 15.3522 3.35076C15.3628 3.35846 15.3681 3.36872 15.3761 3.37899C15.392 3.39951 15.4026 3.41747 15.4105 3.44313L16.0204 5.18527C16.2378 5.80618 16.7495 6.29111 17.3965 6.48867L18.892 6.94538C19.006 6.97873 19.0829 7.08136 19.0829 7.19682L19.0908 7.19425Z" fill={color} />
        <path d="M12.4276 2.57321C12.4276 2.64505 12.3825 2.70919 12.3136 2.73741L11.2371 3.13767C10.8579 3.27879 10.5609 3.57128 10.4204 3.94331L10.0121 5.03632C10.0121 5.03632 10.0015 5.04915 9.9988 5.05685C9.99615 5.06198 9.99085 5.06968 9.9882 5.07481C9.98289 5.08251 9.97494 5.0902 9.96698 5.0979C9.95373 5.10816 9.94047 5.11843 9.92456 5.12612C9.91395 5.12869 9.906 5.13382 9.89539 5.13382C9.88479 5.13382 9.87683 5.13639 9.86623 5.13639H9.83441C9.83441 5.13639 9.81585 5.12869 9.80524 5.12356C9.78933 5.11586 9.77608 5.10816 9.76282 5.09534C9.75486 5.08764 9.74956 5.08251 9.74161 5.07224C9.73895 5.06711 9.73365 5.06198 9.731 5.05685C9.7257 5.04915 9.71774 5.04145 9.71509 5.03376L9.30676 3.94075C9.16888 3.57128 8.87191 3.27622 8.49274 3.1351L7.41623 2.73485C7.34729 2.70919 7.29956 2.64248 7.29956 2.57064C7.29956 2.49367 7.34994 2.42696 7.42683 2.40387L8.4132 2.10367C8.84009 1.97282 9.17683 1.65467 9.32002 1.24415L9.72039 0.0972588C9.7257 0.0844301 9.731 0.0716013 9.73895 0.0613383C9.74426 0.0536411 9.74956 0.0459439 9.75486 0.0408124C9.77077 0.0254179 9.78668 0.0125891 9.81054 0.0048919C9.81585 0.0048919 9.8238 -0.000239599 9.83176 -0.000239599C9.84236 -0.00280535 9.85562 -0.00537109 9.86623 -0.00537109C9.87683 -0.00537109 9.89009 -0.00537109 9.9007 -0.000239599C9.90865 -0.000239599 9.91661 0.00232615 9.92191 0.0048919C9.94047 0.0125891 9.95903 0.0228521 9.97229 0.0356809C9.97759 0.0408124 9.98289 0.0485096 9.9882 0.0536411C9.9988 0.0664698 10.0068 0.0792986 10.0121 0.0946931L10.4124 1.24158C10.5556 1.6521 10.8924 1.97025 11.3193 2.10111L12.3056 2.4013C12.3825 2.42439 12.4329 2.4911 12.4329 2.56807L12.4276 2.57321Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1085_35053">
          <rect width="19.0909" height="20" fill={color}/>
        </clipPath>
      </defs>
    </svg>



  );
};

export default AiStartIcon;
