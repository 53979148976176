// Dropzone.js

import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import AddFileIcon from '../../Svgicons/AddFileIcon';
import theme from '../../../Services/Utils/themes';
import StyledText from '../StyledText';
import { isArray, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import FileMapping from './FileMapping';

function StyledDropzone({ onDrop, accept, selectedFiles, error, setFieldValue, name, setFiles, uploadProgress, buttonDescription, buttonHeading }) {


  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });

  const removeFile = (fileToRemove) => {
    if (isArray(selectedFiles)) {
      // If selectedFiles is an array, filter out the file to be removed
      console.log(selectedFiles, 'selectedFiles in if');

      const updatedFiles = selectedFiles.filter(({ file }) => file.name !== fileToRemove.name);
      setFieldValue(name, updatedFiles.length > 0 ? updatedFiles : null);
      setFiles(updatedFiles); // Set to null if no files left
    } else {
      // If selectedFiles is a single file, just set it to null
      setFieldValue(name, null);
      setFiles(null);
    }
  };


  return (
    <DropzoneContainer className={`flex-column justify-content-between`}>

      <FileMapping selectedFiles={selectedFiles} removeFile={removeFile} />


      <StyledDrop error={error} selectedFiles={selectedFiles} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='d-flex align-items-center justify-content-center flex-wrap gap-3'>
          <AddFileIcon />
          <TextContainer className='d-flex flex-column ml-2'>
            <StyledText $fontSize={'14px'} $fontWeight={600} color={theme.secondary}>{buttonHeading}</StyledText>
            <StyledText $fontSize={'12px'} $fontWeight={400} color={theme.gray}>
              {buttonDescription}
            </StyledText>
          </TextContainer>
        </div>
      </StyledDrop>

    </DropzoneContainer>
  );
}


const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 222px;
  max-height: max-content;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;


export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  border-radius: 50px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
`;


const StyledDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ selectedFiles }) => (!isEmpty(selectedFiles) ? '90px' : '175px')};
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  margin-top:${({ selectedFiles }) => selectedFiles?.length > 2 ? '15px' : '0px'};
  border: ${({ error }) => (error ? `1px dashed ${theme.danger}` : `1px dashed #a5a5a5`)};

  @media only screen and (max-width: 459px) {
    height: ${({ selectedFiles }) => (!isEmpty(selectedFiles) ? '140px' : '175px')};
  }
`;

const TextContainer = styled.div`
  @media only screen and (max-width: 459px) {
    text-align: center;
  }
`;


export const StyledImageCard = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  height: 67px;
  padding: 0 10px;
`;


export const ImageContainer = styled.div`
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
`;


export default StyledDropzone;
