import React from "react";
import { Card, Icon } from "./StyledAiChatbox";
import HandShakeIcon from "../../../Components/Svgicons/HandShakeIcon";
import StyledText from "../../../Components/StyledComponents/StyledText";
import theme from "../../../Services/Utils/themes";
import Example from "./Example";
import BackArrowIcon from "../../../Components/Svgicons/BackArrowIcon";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const ZeroState = ({ handleSendSocketMessage, setText }) => {
  const { t } = useTranslation();
  let Questions = [
    {
      category: t('common:faqs'),
      questions: [
        t('common:nowill'),
        t("common:stepchildren_inheritance"),
        t("common:disinherit_children"),
        t("common:write_valid_will"),
        t("common:power_of_attorney"),
        t("common:estate_inventory"),
        
      ],
    },
    {
      category: t('common:faqs'),
      questions: [
        t("common:gifts_inheritance"),
        t("common:inheritance_difference"),
        t("common:debts_after_death"),
        t("common:inheritance_to_charity"),
        t("common:inheritance_for_unmarried"),
        t("common:international_inheritance"),
      ],
    },
  ];
  return (
    <>
      <div>
        <Icon>
          <HandShakeIcon />
        </Icon>
      </div>

      <div className="d-flex flex-column align-items-center gap-2 mt-4">
        <StyledText color={theme.primary} $fontWeight={600} $fontSize="14px">
          {t('common:welcomeAI')}
        </StyledText>
        <StyledText
          color={theme.secondary}
          $fontWeight={600}
          $fontSize="16px"
          className="text-center"
        >
          {t('common:helpAI')}
        </StyledText>
        <StyledText
          className="text-center mt-2 d-none d-md-block"
          color={theme.gray}
          $fontWeight={400}
          $fontSize="16px"
        >
         {t('common:provideInfo')}
        </StyledText>
        <StyledText
          className="text-center mt-2 d-md-none"
          color={theme.gray}
          $fontWeight={400}
          $fontSize="16px"
        >
          Please go ahead and type your question, and I’ll do my best to provide
          you with accurate and helpful information.
        </StyledText>
      </div>

      <div className="d-none d-md-block">
        <div className="d-flex justify-content-center gap-2 mt-4 ">
          {Questions?.map((example, idx) => (
            <Card key={idx}>
              <StyledText
                color={theme.secondary}
                $fontWeight={600}
                $fontSize="14px"
              >
                {example.category}
              </StyledText>
              <ul className="list-unstyled">
                {example.questions.map((question, index) => (
                  <StyledDiv>
                    <StyledText
                      as={"li"}
                      key={index}
                      color={theme.gray}
                      $fontWeight={400}
                      $fontSize="12px"
                      lh={2}
                      onClick={() => {
                        handleSendSocketMessage({ question, text: null });
                      }}
                      className="cursor-pointer"
                    >
                      {question}&nbsp;
                      <BackArrowIcon size={16} rotate="right" />
                    </StyledText>
                  </StyledDiv>
                ))}
              </ul>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background 0.3s ease, background-color 0.3s ease;

  &:hover {
    background: linear-gradient(
      to right,
      white 0%,
      #d3d3d3 45%,
      #d3d3d3 20%,
      white 100%
    );
    background-color: ${({ theme }) => theme.lightBorder};
    cursor: pointer;
  }
`;

export default ZeroState;
