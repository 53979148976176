import React, { useEffect, useState } from "react";
import { Container } from "./StyledAI";
import HeaderAi from "../Header/HeaderAi";
import ChatboxAi from "../ChatBoxAi/ChatboxAi";
import {
  useDeleteChatMutation,
  useGetChatListingQuery,
  useGetPublicChatListingQuery,
} from "../../../Services/DashboardApi/dashboardApiSlice";
import { svenskaLs } from "../../../Services/Utils/localStorage";
import {
  useAdminDeleteChatMutation,
  useGetAdminChatListingQuery,
} from "../../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { getPublicUserKey, USERTYPE } from "../../../Services/Utils/helper";
import { useLocation } from "react-router-dom";

const SvenskaAi = () => {
  const [limitExceedModal, setLimitExceedModal] = useState(false);
  let userType = svenskaLs?.getObject("userType");
  const lsToken = svenskaLs.getObject("token");
  const location = useLocation();
  const publicUserKey = getPublicUserKey();
  const [deleteChat] = useDeleteChatMutation();
  const [adminDeleteChat] = useAdminDeleteChatMutation();

  const { data: chatListing, refetch } = useGetChatListingQuery(undefined, {
    skip: !(userType === USERTYPE?.CLIENT), // Only fetch if the user is an admin
  });
  const { data: adminListing, refetch: refetchAdmin } =
    useGetAdminChatListingQuery(undefined, {
      skip: !(userType === USERTYPE?.ADMIN), // Only fetch if the user is a client
    });

  const { data: publicChatListing } = useGetPublicChatListingQuery(
    publicUserKey,
    {
      skip: lsToken,
    }
  );

  useEffect(() => {
    if (location.pathname === "/svenska-ai") {
      if (userType === USERTYPE?.CLIENT) {
        refetch();
      } else if (userType === USERTYPE?.ADMIN) {
        refetchAdmin();
      }
    }
  }, [location.pathname]);

  const refetchChat = userType === USERTYPE?.CLIENT ? refetch : refetchAdmin;

  const [chatMessages, setChatMessages] = useState([]);

  const handleNewChat = () => {
    let call =
      userType === USERTYPE?.ADMIN
        ? adminDeleteChat
        : userType === USERTYPE?.CLIENT && deleteChat;
    call().then(() => {
      setChatMessages([]);
      limitExceedModal && setLimitExceedModal(false);
      refetchChat();
    });
  };

  return (
    <Container>
      <HeaderAi
        refetchChat={refetchChat}
        setChatMessages={setChatMessages}
        userType={userType}
        lsToken={lsToken}
        handleNewChat={handleNewChat}
      />
      <ChatboxAi
        chatListing={chatListing || adminListing || publicChatListing}
        setChatMessages={setChatMessages}
        chatMessages={chatMessages}
        userType={userType}
        lsToken={lsToken}
        publicUserKey={publicUserKey}
        handleNewChat={handleNewChat}
        limitExceedModal={limitExceedModal}
        setLimitExceedModal={setLimitExceedModal}
      />
    </Container>
  );
};

export default SvenskaAi;
