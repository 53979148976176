// CombinedProtectedRoute.js
import { Outlet, Navigate } from 'react-router-dom';
import useAuth from '../Services/Hooks/useAuth';
import { USERTYPE } from '../Services/Utils/helper';
import { svenskaLs } from '../Services/Utils/localStorage';

const CombinedProtectedRoute = ({ isAdmin, isClient, isNominee }) => {
    let userType = svenskaLs.getObject("userType");

    const userIsAdmin = userType === USERTYPE?.ADMIN;
    const userIsClient = userType === USERTYPE?.CLIENT;
    const userIsNominee = userType === USERTYPE?.NOMINEE;


    if ((userIsAdmin && isAdmin) || (userIsClient && isClient) || (userIsNominee && isNominee)) {
        return <Outlet />;
    }

    return <Navigate to={userIsAdmin ? "/admin-login" : "/login"} replace />;
};

export default CombinedProtectedRoute;
