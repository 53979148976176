const BackArrowIcon = ({
  size = 24,
  rotate = "left",
  transition,
  ...props
}) => {
  const getRotateDeg = (val) => {
    switch (val) {

      case 'left':
        return '360';
      case 'right':
        return '180';
      default:
        return '0';
    }
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ transform: `rotate(${getRotateDeg(rotate)}deg)` }}
    >
      <path
        d="M9.57 5.93005L3.5 12.0001L9.57 18.0701"
        stroke="#0A0509"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4999 12H3.66992"
        stroke="#0A0509"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    
  );
};

export default BackArrowIcon;
