import React from 'react'

const KeyIcon = ({size = 54}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.5275 33.5925C39.8925 38.205 33.255 39.6225 27.4275 37.8L16.83 48.375C16.065 49.1625 14.5575 49.635 13.4775 49.4775L8.57246 48.8025C6.95246 48.5775 5.44496 47.0475 5.19746 45.4275L4.52246 40.5225C4.36496 39.4425 4.88246 37.935 5.62496 37.17L16.2 26.595C14.4 20.745 15.795 14.1075 20.43 9.495C27.0675 2.8575 37.845 2.8575 44.505 9.495C51.165 16.1325 51.165 26.955 44.5275 33.5925Z" stroke="white" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5024 39.3525L20.6774 44.5275" stroke="white" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32.625 24.75C34.489 24.75 36 23.239 36 21.375C36 19.511 34.489 18 32.625 18C30.761 18 29.25 19.511 29.25 21.375C29.25 23.239 30.761 24.75 32.625 24.75Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default KeyIcon