const StarsIcon = ({ width = 16, height = 15, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1085_33651)">
        <path d="M11.8434 9.60523C11.8434 9.75423 11.7397 9.88794 11.5786 9.94334L9.09214 10.7685C8.21624 11.0589 7.53229 11.6644 7.21017 12.4304L6.26588 14.6864C6.25926 14.7036 6.24381 14.715 6.23499 14.7303C6.22616 14.7437 6.21954 14.7551 6.20851 14.7666C6.19307 14.7838 6.17762 14.7991 6.15997 14.8143C6.13129 14.8373 6.0982 14.8564 6.0629 14.8697C6.04083 14.8774 6.01877 14.885 5.9945 14.8869C5.97244 14.8927 5.95038 14.8946 5.92611 14.8946L5.8533 14.8869C5.83124 14.885 5.80917 14.8774 5.7849 14.8697C5.7474 14.8564 5.7143 14.8354 5.68562 14.8124C5.66797 14.7991 5.65253 14.7838 5.63708 14.7666C5.62826 14.757 5.62164 14.7456 5.61502 14.736C5.60399 14.7188 5.58634 14.7055 5.57972 14.6864L4.63542 12.4304C4.31551 11.6663 3.63156 11.0589 2.75566 10.7685L0.269168 9.94334C0.108108 9.88985 0 9.75614 0 9.60523C0 9.4486 0.11914 9.30915 0.291231 9.26331L2.56592 8.64249C3.54993 8.37315 4.32654 7.71414 4.65969 6.86792L5.58634 4.50118C5.59737 4.47444 5.61281 4.44961 5.62826 4.42859C5.63929 4.41331 5.65032 4.39994 5.66797 4.38657C5.70106 4.3541 5.74519 4.33117 5.79594 4.31398C5.80917 4.30825 5.82682 4.30443 5.84447 4.30252C5.87095 4.29488 5.89743 4.29297 5.92611 4.29297C5.95479 4.29297 5.98347 4.29488 6.00774 4.30252C6.02539 4.30443 6.04083 4.31016 6.05628 4.31398C6.1004 4.32926 6.14012 4.35219 6.17542 4.38084C6.18866 4.3923 6.20189 4.40567 6.21292 4.41713C6.23499 4.44387 6.25484 4.47062 6.26588 4.50309L7.19252 6.86983C7.52346 7.71605 8.30229 8.37506 9.28409 8.6444L11.5588 9.26522C11.7331 9.31106 11.85 9.45051 11.85 9.60714L11.8434 9.60523Z" fill="#B06729" />
        <path d="M15.8871 5.35747C15.8871 5.43961 15.8298 5.5122 15.7415 5.54276L14.3802 5.99548C13.9015 6.15403 13.5264 6.4864 13.3499 6.90474L12.8314 8.13872C12.8314 8.13872 12.8204 8.15401 12.8138 8.16356C12.8094 8.1712 12.805 8.17693 12.8005 8.18266C12.7917 8.19221 12.7829 8.19985 12.7741 8.20749C12.7586 8.21895 12.741 8.23041 12.7211 8.23805C12.7079 8.24187 12.6968 8.24569 12.6836 8.2476C12.6704 8.24951 12.6593 8.25143 12.6461 8.25143L12.6064 8.2476C12.5932 8.2476 12.5821 8.24187 12.5689 8.23805C12.549 8.23041 12.5314 8.21895 12.5137 8.20749C12.5027 8.19985 12.4961 8.19221 12.4872 8.18266C12.4828 8.17693 12.4784 8.1712 12.474 8.16547C12.4674 8.15592 12.4586 8.14827 12.4542 8.13872L11.9357 6.90474C11.7614 6.4864 11.3863 6.15403 10.9075 5.99548L9.54626 5.54276C9.45801 5.51411 9.39844 5.43961 9.39844 5.35747C9.39844 5.27152 9.46242 5.19511 9.55729 5.17027L10.8016 4.83026C11.34 4.68317 11.7658 4.32215 11.9489 3.85988L12.4564 2.56285C12.463 2.54757 12.4696 2.5342 12.4784 2.52274C12.485 2.5151 12.4917 2.50746 12.5005 2.49982C12.5181 2.48263 12.5424 2.46925 12.5711 2.4597C12.5799 2.45779 12.5887 2.45397 12.5976 2.45397C12.6108 2.45015 12.6262 2.44824 12.6417 2.44824C12.6571 2.44824 12.6726 2.44824 12.6858 2.45397C12.6946 2.45397 12.7035 2.45779 12.7123 2.4597C12.7366 2.46734 12.7586 2.48072 12.7763 2.496C12.7851 2.50173 12.7895 2.50937 12.7961 2.51701C12.8094 2.53229 12.8182 2.54566 12.8248 2.56476L13.3323 3.86179C13.5132 4.32406 13.939 4.68508 14.4773 4.83217L15.7217 5.17219C15.8165 5.19702 15.8805 5.27343 15.8805 5.35938L15.8871 5.35747Z" fill="#B06729" />
        <path d="M10.3373 1.91291C10.3373 1.9664 10.2998 2.01416 10.2424 2.03517L9.34666 2.33316C9.03116 2.43822 8.78405 2.65598 8.66712 2.93296L8.32735 3.74671C8.32735 3.74671 8.31853 3.75626 8.31632 3.76199C8.31411 3.76581 8.3097 3.77154 8.30749 3.77536C8.30308 3.78109 8.29646 3.78682 8.28984 3.79255C8.27881 3.80019 8.26778 3.80783 8.25454 3.81356C8.24572 3.81547 8.2391 3.81929 8.23027 3.81929C8.22145 3.81929 8.21483 3.8212 8.206 3.8212H8.17953C8.17953 3.8212 8.16409 3.81547 8.15526 3.81165C8.14202 3.80592 8.13099 3.80019 8.11996 3.79064C8.11334 3.78491 8.10893 3.78109 8.10231 3.77345C8.1001 3.76963 8.09569 3.76581 8.09348 3.76199C8.08907 3.75626 8.08245 3.75053 8.08025 3.7448L7.74048 2.93105C7.62575 2.65598 7.37864 2.43631 7.06314 2.33125L6.16739 2.03326C6.11003 2.01416 6.07031 1.96449 6.07031 1.911C6.07031 1.8537 6.11223 1.80403 6.17621 1.78684L6.99696 1.56335C7.35217 1.46593 7.63237 1.22906 7.75151 0.923431L8.08466 0.069572C8.08907 0.0600211 8.09348 0.05047 8.1001 0.0428292C8.10452 0.0370986 8.10893 0.0313681 8.11334 0.0275477C8.12658 0.0160865 8.13982 0.00653546 8.15967 0.000804861C8.16409 0.000804861 8.1707 -0.00301554 8.17732 -0.00301554C8.18615 -0.00492574 8.19718 -0.00683594 8.206 -0.00683594C8.21483 -0.00683594 8.22586 -0.00683594 8.23469 -0.00301554C8.24131 -0.00301554 8.24792 -0.00110534 8.25234 0.000804861C8.26778 0.00653546 8.28323 0.0141763 8.29426 0.0237273C8.29867 0.0275477 8.30308 0.0332782 8.30749 0.0370986C8.31632 0.0466496 8.32294 0.0562007 8.32735 0.0676618L8.6605 0.921521C8.77964 1.22715 9.05984 1.46402 9.41505 1.56144L10.2358 1.78493C10.2998 1.80212 10.3417 1.85179 10.3417 1.90909L10.3373 1.91291Z" fill="#B06729" />
      </g>
      <defs>
        <clipPath id="clip0_1085_33651">
          <rect width="15.8853" height="14.89" fill="white" />
        </clipPath>
      </defs>
    </svg>


  );
};

export default StarsIcon;
