import styled, { keyframes } from "styled-components";
import theme from "../../../Services/Utils/themes";


export const HeaderContainer = styled.div`
height: 60px;
width: 100%;
border-radius: 8px 8px 0 0;
z-index: 1000;
position: sticky;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 47px;

@media (max-width: 768px) {
  padding: 0 15px;
}

`;

export const HeaderTopContainer = styled.div`
display: flex;
flex-direction: column;
background-color: ${theme.white};
box-shadow: 1px 1px 40px 0px #0000001A;
border-radius: 8px 8px 0 0;
z-index: 1000;
position: sticky;
align-items: center;
background-color: ${theme.white};

`;

export const Button = styled.div`
background: white;
  wdith: 100%;
  border: 1px solid ${theme.primary};
  height: 100%;
  display: flex;
  width: 120px;
  gap: 5px;
  height:35px;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;

   &:hover {
background: ${theme.lightBlack};
    
  }

`;