import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import StyledText from "../Components/StyledComponents/StyledText";
import { svenskaLs } from "../Services/Utils/localStorage";
import { useGetOrderRefMutation } from "../Services/loginApi/loginApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../Services/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const RedirectPage = () => {
  let orderRef = svenskaLs.getObject("orderRef");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderRefCollect] = useGetOrderRefMutation();

  useEffect(() => {

    if (svenskaLs?.getItem('pageReload') === null) {
      svenskaLs.setItem('pageReload', 1);
      orderRefCollect(orderRef)
        .then((resp) => {
          if (resp?.data?.token) {
            dispatch(
              setCredentials({
                token: { accessToken: resp?.data?.token },
                account: resp?.data?.userInfo,
                userType: resp?.data?.userInfo?.userType,
              })
            );
            svenskaLs.removeItem("orderRef");
            svenskaLs.setItem("loadingComplete", "true");
            svenskaLs.removeItem('pageReload');
            navigate("/dashboard");
          } else {
            setError(resp?.error?.data?.message || "Unknown error occurred");
            svenskaLs.removeItem('pageReload');
          }
        })
        .catch((error) => {
          setError("Failed to fetch data. Please check your internet connection.");
          svenskaLs.removeItem('pageReload');
        })
        .finally(() => {
          setLoading(false);
          svenskaLs.removeItem('pageReload');
        });
    } else {
      window.location.href = "/login";
      svenskaLs.removeItem('pageReload');
      svenskaLs.removeItem("orderRef");
    }
  }, [svenskaLs]);
  if (loading) {
    return (
      <StyledContainer className="rounded px-5 pt-4 pb-3 d-flex flex-column align-items-center gap-3">
        <div className="spinner-border text-primary loader" role="status" />
        <StyledText>Redirecting...</StyledText>
      </StyledContainer>
    );
  }

  if (error) {
    return (
      svenskaLs.removeItem('pageReload'),
      toast.info(
        <div>
          {t("common:accountNotFound")}&nbsp;
          <StyledText
            onClick={() => navigate("/signup")}
            className="fw-bold text-decoration-underline"
          >
            {t("common:signUp")}
          </StyledText>
          &nbsp;
          {t("common:getStarted")}
        </div>,
        {
          autoClose: 5000,
        }
      ),

      setTimeout(() => {
        window.location.href = "/login";
      }, 3000)


    );
  }

  return null; // or any other component you want to render after redirection
};

const StyledContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  .loader {
    height: 50px;
    width: 50px;
  }
`;

export default RedirectPage;
