import React from 'react'
import StyledText from '../../../Components/StyledComponents/StyledText'
import CustomDropdown from '../../../Components/dropdown/CustomDropDown'
import { DropdownItem } from 'react-bootstrap'
import StyledRadioButton from '../../../Components/StyledComponents/StyledRadioButton'
import SortIcon from '../../../Components/Svgicons/SortIcon'
import StyledIconInput from '../../../Components/StyledComponents/StyledIconInput'
import StyledButton from '../../../Components/StyledComponents/StyledButton'
import AddIcon from '../../../Components/Svgicons/AddIcon'
import { useTranslation } from 'react-i18next'
import theme from '../../../Services/Utils/themes'
import styled from 'styled-components'
import { svenskaLs } from '../../../Services/Utils/localStorage'
import DocUploadIcon from '../../../Components/Svgicons/DocUploadIcon'

const AIMenuSection = ({ sorted, handleSortBy, handleSearch, setAddClientModal, setIsEdit }) => {
    const { t } = useTranslation();
    const lang = svenskaLs.getItem("lng");


 
    return (
        <Container lang={lang}>
            <MobileHeaderContainer lang={lang}>
                <div className='d-flex flex-column'>
                    <StyledText $fontWeight={600} color={theme.secondary} $fontSize="14px">{t("common:aiHeading")}</StyledText>
                    <StyledText $fontWeight={400} color={theme.doveGray} $fontSize="12px">{t("common:aiDescription")}</StyledText>
                </div>
            </MobileHeaderContainer>


            <SeacrhContainer lang={lang}>

                <ResponsiveSearch onChange={handleSearch} placeholder={t("common:searchDocument")} lang={lang}/>
                <IconButtonContainer lang={lang}>
                    <StyledButton variant="primary" icon={<DocUploadIcon color={theme.white} />} onClick={() => { setIsEdit({ data: {}, state: false }); setAddClientModal(true) }}/>
                </IconButtonContainer>
                <TextButtonContainer lang={lang}>
                    <StyledButton variant="primary" icon={<DocUploadIcon color={theme.white} />} onClick={() => { setIsEdit({ data: {}, state: false }); setAddClientModal(true) }}>
                        {t("common:uploadAIDocument")}
                    </StyledButton>
                </TextButtonContainer>

            </SeacrhContainer>
        </Container>
    )
}



 const TextButtonContainer = styled.div`
display:block;
 @media (max-width: ${({lang}) => (lang === 'en' ? '1071px' : '1121px')}) {
            display: none;
        
    }
`;
 const IconButtonContainer = styled.div`
display:none;
 @media (max-width: ${({lang}) => (lang === 'en' ? '1071px' : '1121px')}) {
            display: block;
        
    }
`;

 const SortContainer = styled.div`
display:block;
 @media (max-width: ${({lang}) => (lang === 'en' ? '975px' : '1004px')}) {
            display: none;
        
    }
`;

 const MobileSortContainer = styled.div`
display:none;
 @media (max-width: ${({lang}) => (lang === 'en' ? '975px' : '1004px')}) {
            display: block;
        
    }
`;

 const Container = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
 @media (max-width: ${({lang}) => (lang === 'en' ? '975px' : '1004px')}) {
            flex-direction: column;
            gap:10px;
        
    }
`;

 const MobileHeaderContainer = styled.div`

 @media (max-width: ${({lang}) => (lang === 'en' ? '975px' : '1004px')}) {
            display:flex;
align-items:center;
justify-content:space-between;
width: 100%;
        
    }
`;

 const SeacrhContainer = styled.div`
display:flex;
align-items:center;
gap:10px;
 @media (max-width: ${({lang}) => (lang === 'en' ? '975px' : '1004px')}) {
width: 100%;
        
    }
`;


 const ResponsiveSearch = styled(StyledIconInput)`
width: 264px;
 @media (max-width: ${({lang}) => (lang === 'en' ? '975px' : '1004px')}) {
            width: 100%;
        
    }

`



export default AIMenuSection