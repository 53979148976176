import React from "react";
import { ErrorMessage, FastField } from "formik";
import Proptypes from "prop-types";
import { formErrorMessage } from "./FormErrorMessage";
import StyledInput from "../StyledComponents/StyledInput";
import { useTranslation } from "react-i18next";

const FormikInputField = ({
  name,
  forms,
  maxChar,
  index,
  isReadOnly,
  isShowError,
  ...props
}) => {
  const { i18n } = useTranslation();

  return (
    <FastField name={name}>
      {({ field, form: { touched, errors, submitCount, ...rest } }) => (
        <>
          <StyledInput
            {...field}
            key={i18n.language}
            value={isReadOnly ? "" : field.value}
            // isValid={submitCount && touched[field.name] && !errors[field.name]}
            isInvalid={submitCount && touched[field.name] && errors[field.name]}
            id={props.id}
            {...props}
          />
          {props.type !== "number" && maxChar && field && field.value && (
            <code className="text-muted float-right">
              {field.value.length}/{maxChar}
            </code>
          )}
          {submitCount > 0 && !isShowError && (
            <ErrorMessage name={field.name} render={formErrorMessage} />
          )}
        </>
      )}
    </FastField>
  );
};

FormikInputField.propTypes = {
  name: Proptypes.string.isRequired,
  placeholder: Proptypes.string,
  type: Proptypes.string,
  as: Proptypes.string,
  rows: Proptypes.string,
};
export default React.memo(FormikInputField);
